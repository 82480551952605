import React, { useCallback, useState } from 'react';
import keys from 'lodash/keys';
import size from 'lodash/size';
import omit from 'lodash/omit';

import { SimpleModalButton } from '../SimpleModalButton';
import { AlertMessage } from '../../AlertMessage';

import { DropzoneImageHelper } from './components/DropzoneImageHelper';

import { DropzoneModalButtonRequiredProps } from '../../dropzone/DropzoneModalButton';
import { ErrorMessage, I18nText, ID } from '../../../types';

import {
  DropzoneImageHelperFileId,
  DropzoneImageHelperFiles,
  DropzoneImageHelperRequiredProps
} from './components/DropzoneImageHelper/DropzoneImageHelper.types';

interface DropzoneModalButtonBaseProps {
  value?: ID;
  disabled?: boolean;
  submitErrorMessage?: ErrorMessage;
  resetOnClose?: boolean;
  maxFiles?: number;
}

interface DropzoneModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface DropzoneModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

interface DropzoneModalButtonWithOnSubmitProps {
  onSubmit: (value: ID) => Promise<unknown>;
  onChange?: never;
}

interface DropzoneModalButtonWithOnChangeProps {
  onSubmit?: never;
  onChange: (value: ID) => void;
}

type DropzoneModalButtonProps = DropzoneModalButtonBaseProps &
  (DropzoneModalButtonWithTextProps | DropzoneModalButtonWithI18nTextProps) &
  (DropzoneModalButtonWithOnChangeProps | DropzoneModalButtonWithOnSubmitProps);

const initialFile = {};

function DropzoneModalButton({
  type,
  value,
  disabled,
  onChange,
  className,
  i18nTitle,
  icon,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  i18nText,
  buttonText,
  submitErrorMessage,
  resetOnClose,
  onSubmit,
  maxFiles
}: DropzoneModalButtonProps &
  DropzoneModalButtonRequiredProps &
  DropzoneImageHelperRequiredProps) {
  const [uploadedFile, setUploadedFile] =
    useState<DropzoneImageHelperFiles>(initialFile);
  const [uploadedFileBeforeOpen, setUploadedFileBeforeOpen] =
    useState<DropzoneImageHelperFiles>(initialFile);
  const [uploadedFileId, setUploadedFileId] =
    useState<DropzoneImageHelperFileId>(value);
  const [uploadedFileIdBeforeOpen, setUploadedFileIdBeforeOpen] =
    useState<DropzoneImageHelperFileId>(value);

  const handleOpen = useCallback<() => void>(() => {
    setUploadedFileBeforeOpen(uploadedFile);
    setUploadedFileIdBeforeOpen(uploadedFileId);
  }, [
    uploadedFile,
    uploadedFileId,
    setUploadedFileBeforeOpen,
    setUploadedFileIdBeforeOpen
  ]);

  const handleCancel = useCallback<() => void>(() => {
    setUploadedFile(uploadedFileBeforeOpen);
    setUploadedFileId(uploadedFileIdBeforeOpen);
  }, [uploadedFileBeforeOpen, uploadedFileIdBeforeOpen]);

  const handleClose = useCallback<() => void>(() => {
    const processingFileIds = keys(uploadedFile).filter(
      (key) => uploadedFile[key].state === 'processing'
    );

    if (size(processingFileIds) > 0) {
      setUploadedFile((prevState) =>
        omit<DropzoneImageHelperFiles>(prevState, processingFileIds)
      );
    }
  }, [uploadedFile]);

  const handleAfterSubmit = useCallback<() => void>(() => {
    if (resetOnClose) {
      setUploadedFile(initialFile);
      setUploadedFileId(undefined);
    } else {
      setUploadedFileBeforeOpen(uploadedFile);
      setUploadedFileIdBeforeOpen(uploadedFileId);
    }
  }, [resetOnClose, uploadedFileId, uploadedFile]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (onChange) {
      onChange(uploadedFileId);
      handleAfterSubmit();
    } else {
      return onSubmit?.(uploadedFileId).then(() => handleAfterSubmit());
    }
  }, [onChange, uploadedFileId, handleAfterSubmit, onSubmit]);

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 overflow-y-auto p-4"
      i18nTitle={i18nTitle}
      icon={icon}
      modalIcon={modalIcon}
      {...(i18nText ? { i18nText } : { buttonText })}
      i18nSubmitText={i18nSubmitText}
      disabled={
        size(
          keys(uploadedFile).filter(
            (key) => uploadedFile[key].state === 'processing'
          )
        ) > 0 || size(uploadedFile) > maxFiles
      }
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <div className="mt-5">
        <AlertMessage addClassName="m-4" message={submitErrorMessage} />
        <DropzoneImageHelper
          type={type}
          initialFile={uploadedFile}
          initialFileId={uploadedFileId}
          value={value}
          disabled={
            disabled ||
            size(
              keys(uploadedFile).filter(
                (key) => uploadedFile[key].state === 'processing'
              )
            ) > 0
          }
          onChange={setUploadedFileId}
          onChangeFile={setUploadedFile}
          maxFiles={maxFiles}
        />
      </div>
    </SimpleModalButton>
  );
}

export default DropzoneModalButton;
