import React, { useState } from 'react';
import size from 'lodash/size';
import take from 'lodash/take';

import { I18nText } from '../../../../../../../types';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategories } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavItem } from '../ThreeDStockIndexPageProductsCategoriesNavItem';
import { ThreeDStockIndexPageProductsCategoriesNavAmountButton } from '../ThreeDStockIndexPageProductsCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { productsKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageProductsCategoriesNavListProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: ProductsFiltersNavBasePath;
  isShowAll?: boolean;
  parentCategories: ThreeDStockIndexPageProductsCategoriesNavCategories;
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategories;
}

function ThreeDStockIndexPageProductsCategoriesNavList({
  allCustomI18nText,
  filtersNavBasePath,
  isShowAll = false,
  parentCategories,
  productCategories
}: ThreeDStockIndexPageProductsCategoriesNavListProps) {
  const [showAll, setShowAll] = useState(isShowAll);

  const allProducts = (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextPureLinkHelper
          href={filtersNavBasePath}
          className="focus:outline-none flex-1 px-2 text-sm"
          i18nText={allCustomI18nText || productsKeys.all}
        />
      </div>
    </div>
  );

  if (size(parentCategories) <= 5) {
    return (
      <>
        {allProducts}
        {parentCategories.map((category) => (
          <ThreeDStockIndexPageProductsCategoriesNavItem
            key={category.id}
            category={category}
            categories={productCategories}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? parentCategories
    : take(parentCategories, 5);

  return (
    <>
      {allProducts}

      {showedCategories.map((category) => (
        <ThreeDStockIndexPageProductsCategoriesNavItem
          key={category.id}
          category={category}
          categories={productCategories}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}

      {!isShowAll && (
        <ThreeDStockIndexPageProductsCategoriesNavAmountButton
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavList;
