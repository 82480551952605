import React, { useMemo } from 'react';
import { Control, FieldPath } from 'react-hook-form';

import { TeamOwnerNanoID } from '../../teamsTypes';
import { I18nText, IsDisabled, PayoneerAccountTypes } from '../../../../types';

import {
  FETCH_USER_SETTINGS_QUERY,
  FetchUserSettingsQueryResponse
} from '../../../users/queries/fetchUserSettings.query';

import { useUser } from '../../../users/hooks/useUser';

import { UserCache } from '../../../users/UserCache';
import { SelectOptionType } from '../../../../helpers/Select';
import { SelectField } from '../../../../helpers/FormFields/SelectField';
import compact from 'lodash/compact';

interface CompanyPayoneerAccountSelectFieldProps<FormDataType> {
  name: FieldPath<FormDataType>;
  control: Control<FormDataType>;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  error?: string | null;
  companyOwnerNanoId: TeamOwnerNanoID;
  disabled?: IsDisabled;
}

function CompanyPayoneerAccountSelectField<FormDataType>({
  control,
  i18nLabel,
  name,
  i18nPlaceholder,
  error,
  companyOwnerNanoId,
  disabled
}: CompanyPayoneerAccountSelectFieldProps<FormDataType>) {
  const { user, userLoading, userError } =
    useUser<FetchUserSettingsQueryResponse>({
      cacheKey: UserCache.userSettingsCacheKey(),
      uuid: companyOwnerNanoId,
      query: FETCH_USER_SETTINGS_QUERY
    });

  const options = useMemo<SelectOptionType[]>(
    () =>
      compact([
        user?.payoneerId && {
          value: PayoneerAccountTypes.PAYONEER_ID,
          label: user.payoneerId
        },
        user?.payoneerIdPe && {
          value: PayoneerAccountTypes.PAYONEER_ID_PE,
          label: user.payoneerIdPe
        },
        user?.payoneerIdAvltd && {
          value: PayoneerAccountTypes.PAYONEER_ID_AVLTD,
          label: user.payoneerIdAvltd
        },
        user?.payoneerIdPeAvltd && {
          value: PayoneerAccountTypes.PAYONEER_ID_PE_AVLTD,
          label: user.payoneerIdPeAvltd
        }
      ]),
    [
      user?.payoneerId,
      user?.payoneerIdPe,
      user?.payoneerIdAvltd,
      user?.payoneerIdPeAvltd
    ]
  );

  return (
    <SelectField
      error={error || userError}
      control={control}
      disabled={disabled || userLoading}
      i18nLabel={i18nLabel}
      name={name}
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      options={options}
      i18nPlaceholder={i18nPlaceholder}
    />
  );
}

export default CompanyPayoneerAccountSelectField;
