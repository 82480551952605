import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import {
  PayoneerV4PayoutID,
  PerformRequestPayoneerV4PayoutCacheKeys
} from '../../payoneerV4PayoutsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { PayoneerV4PayoutBffUrl } from '../../PayoneerV4PayoutsBffUrl';

interface PerformRequestPayoneerV4PayoutOptions {
  cacheKeys?: PerformRequestPayoneerV4PayoutCacheKeys;
}

export type PerformRequestPayoneerV4PayoutInputData = {
  actionOptions: {
    url: string;
  };
};

export type PerformRequestPayoneerV4PayoutInput = {
  payoneerV4PayoutId: PayoneerV4PayoutID;
};

export type PerformRequestPayoneerV4PayoutError = BffApiUpdateItemError;

function usePerformRequestPayoneerV4Payout<
  PerformRequestPayoneerV4PayoutResponseType = unknown
>({ cacheKeys }: PerformRequestPayoneerV4PayoutOptions = {}) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    PerformRequestPayoneerV4PayoutInputData,
    PerformRequestPayoneerV4PayoutResponseType,
    PerformRequestPayoneerV4PayoutError
  >({ cacheKeys });

  const performRequestPayoneerV4Payout = useCallback<
    (input: PerformRequestPayoneerV4PayoutInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        actionOptions: {
          url: PayoneerV4PayoutBffUrl.performRequest(input.payoneerV4PayoutId)
        }
      }),
    [postQuery]
  );

  return {
    performRequestPayoneerV4PayoutData: postQueryData,
    performRequestPayoneerV4PayoutError: postQueryError,
    performRequestPayoneerV4PayoutLoading: postQueryLoading,
    performRequestPayoneerV4PayoutErrorMessage: postQueryErrorMessage,
    performRequestPayoneerV4Payout,
    performRequestPayoneerV4PayoutReset: postQueryReset
  };
}

export default usePerformRequestPayoneerV4Payout;
