import { words } from '../../locales/keys';
import { permissionsKeys } from './../../locales/keys/models/permissions';
import { FetchPermissionsSortTypes } from './permissionsTypes';

export const INITIAL_PERMISSIONS_FILTERS = {};
export const INITIAL_PERMISSIONS_PAGE = 1;
export const INITIAL_PERMISSIONS_SORT = [
  FetchPermissionsSortTypes.CREATED_AT_DESC
];
export const INITIAL_PERMISSIONS_LIMIT = 50;

export const enum PermissionsPermissions {
  READ_ADMIN_MENU_PERMISSIONS_LINK = 'read_admin_menu_permissions_link',
  READ_PERMISSIONS_INDEX_PAGE = 'read_permissions_index_page',
  READ_PERMISSIONS_SEARCH_FILTERS_POPOVER = 'read_permissions_search_filters_popover'
}

export const permissionTypes = [
  { value: 'index', i18nLabel: permissionsKeys.scopes.index },
  { value: 'create', i18nLabel: permissionsKeys.scopes.create },
  { value: 'read', i18nLabel: permissionsKeys.scopes.read },
  { value: 'update', i18nLabel: permissionsKeys.scopes.update },
  { value: 'delete', i18nLabel: permissionsKeys.scopes.delete },
  { value: 'download', i18nLabel: permissionsKeys.scopes.download }
];

export const permissionTypesOptions = [
  { value: null, i18nLabel: words.anyType },
  ...permissionTypes
];
