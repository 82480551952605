import cl from 'classnames';

import { IconsEnum } from '../../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../../helpers/buttons/PureIconButtonHelper';

interface SecondaryMenuWrapperCloseButtonProps {
  isSecondaryMenuKeepOpen: boolean;
  closeSecondaryMenu: () => void;
}

function SecondaryMenuWrapperCloseButton({
  isSecondaryMenuKeepOpen,
  closeSecondaryMenu
}: SecondaryMenuWrapperCloseButtonProps) {
  return (
    <div
      data-id="nav-secondary-btn-wrap"
      className={cl(
        'flex-shrink w-16 z-10',
        isSecondaryMenuKeepOpen ? 'xl:hidden' : null
      )}
      onClick={closeSecondaryMenu}
    >
      <PureIconButtonHelper
        className="-ml-5 bg-gray-50 border border-gray-200 dark:bg-gray-850 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-gray-200 dark:text-gray-500 hover:bg-gray-200 hover:text-gray-600 m-1 my-3 p-2 rounded-full text-gray-400"
        icon={IconsEnum.X}
        onClick={closeSecondaryMenu}
      />
    </div>
  );
}

export default SecondaryMenuWrapperCloseButton;
