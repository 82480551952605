import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  CreateItemCacheKeys,
  CreatedAt,
  DateFilterType,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsFilters,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  NanoIDFilter,
  NanoIdFilterType,
  UUID,
  UpdatedAt,
  UuidFilterType
} from '../../types';
import { UserAvatarLinkUser } from '../common/helpers/UserAvatarLink';
import { InvoiceID } from '../invoices/invoicesTypes';
import {
  JournalRecordAccountId,
  JournalRecordAmount,
  JournalRecordID,
  JournalRecordSide
} from '../journalRecords/journalRecordsTypes';

export interface FetchJournalRecordImportFilters {
  userNanoId?: NanoIDFilter;
}

export interface FetchFinJournalRecordImportFilters {
  userNanoID?: NanoIDFilter;
  createdAt?: DateFilterType;
  id?: IdFilterType;
  nanoId?: NanoIdFilterType;
  updatedAt?: DateFilterType;
  userId?: IdFilterType;
  uuid?: UuidFilterType;
}

export const enum FetchJournalRecordImportsSortTypes {
  ID_DESC = 'ID_DESC'
}

export type JournalRecordImportID = ID;
export type JournalRecordsImportUUID = UUID;
export type JournalRecordsImportName = string;
export type FetchJournalRecordImportsCacheKey = FetchItemsCacheKey;
export type JournalRecordImportAccountingTransactionID = number;
export type FetchJournalRecordImportsSort = FetchItemsSort;
export type FetchJournalRecordImportsPage = FetchItemsPage;
export type FetchJournalRecordImportsLimit = FetchItemsLimit;
export type JournalRecordImportAccountingTransactionInvoiceID = number;
export type JournalRecordImportAccountingTransactionInvoiceComment = string;
export type JournalRecordImportAccountingTransactionInvoiceGeneralLedgerID =
  number;

export type JournalRecordImportAccountingTransactionJournalEntryID = number;
export type JournalRecordImportAccountingTransactionJournalEntryJournalRecordID =
  JournalRecordID;
export type JournalRecordImportAccountingTransactionJournalEntryJournalRecordAmount =
  JournalRecordAmount;
export type JournalRecordImportAccountingTransactionJournalEntryJournalRecordSide =
  JournalRecordSide;
export type JournalRecordImportAccountingTransactionJournalEntryJournalRecordAccountID =
  JournalRecordAccountId;

export type CreateJournalRecordImportCacheKeysType = CreateItemCacheKeys;
export type GenerateJournalRecordImportCacheKeysType = CreateItemCacheKeys;

export type DeleteJournalRecordImportCacheKeysType = DeleteItemCacheKeys;

export type JournalRecordsImportCreateddAt = CreatedAt;
export type JournalRecordsImportUpdatedAt = UpdatedAt;

export type JournalRecordsImportURL = string;
export type JournalRecordsImportStatus = string;
export type JournalRecordsImportProgress = number;
export type JournalRecordsImportRowCount = number;
export type JournalRecordsImportErrorMessage = string;
export type JournalRecordsImportErrorBacktrace = string;
export type JournalRecordsImportUser = UserAvatarLinkUser;

export type FetchJournalRecordImportsErrorMessage = FetchItemsErrorMessage;
export type FetchJournalRecordImportsTotalCount = FetchItemsTotalCount;
export type FetchJournalRecordImportsFetched = FetchItemsFetched;

export type FetchJournalRecordImportsSortJournalRecordImports =
  FetchItemsSortItems;
export type FetchJournalRecordImportsIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchJournalRecordImportsPaginateJournalRecordImports =
  FetchItemsPaginateItems;
export type FetchJournalRecordImportsPrefetchJournalRecordImports =
  FetchItemsPrefetchItems;

export type FetchJournalRecordImportsChecked = Checked;
export type FetchJournalRecordImportsOnSetCheckedIds = OnSetCheckedIds;
export type FetchJournalRecordImportsCheckedHash = CheckedHashItem;
export type FetchJournalRecordImportsCheckedAll = CheckedAll;
export type FetchJournalRecordImportsOnCheckAll = OnCheckAll;

export type FetchFinJournalRecordImportsGqlQuery = FetchItemsGqlQuery;
export type FetchFinJournalRecordImportsCacheKey = FetchItemsCacheKey;
export type FetchFinJournalRecordImportsFilters = FetchItemsFilters;
export type FetchFinJournalRecordImportsSort = FetchItemsSort;
export type FetchFinJournalRecordImportsPage = FetchItemsPage;
export type FetchFinJournalRecordImportsLimit = FetchItemsLimit;
export type FetchFinFilterJournalRecordImports =
  FetchItemsFilterItems<FetchFinJournalRecordImportsFilters>;

export interface FetchJournalRecordImportsJournalRecordType {
  uuid: JournalRecordsImportUUID;
  createdAt: JournalRecordsImportCreateddAt;
  updatedAt: JournalRecordsImportUpdatedAt;
  name: JournalRecordsImportName;
  id: JournalRecordImportID;
  url: JournalRecordsImportURL;
  status: JournalRecordsImportStatus;
  progress: JournalRecordsImportProgress;
  rowsCount: JournalRecordsImportRowCount;
  errorMessage: JournalRecordsImportErrorMessage;
  user: JournalRecordsImportUser;
  invoiceIds: InvoiceID[];
}

export interface FetchJournalRecordImportResponse {
  status: string;
  invoice: FetchJournalRecordImportsJournalRecordType;
  error?: Error;
}
