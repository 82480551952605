import { gql } from 'graphql-request';

export const UPDATE_TEAM_SUB_CATEGORIES_QUERY = gql`
  mutation UpdateTeamSubCategories($uuid: ID!, $subCategoryIds: [ID!]!) {
    updateTeamSubCategories(
      input: { uuid: $uuid, subCategoryIds: $subCategoryIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
