import { gql } from 'graphql-request';

export const UPDATE_TEAM_ROLES_QUERY = gql`
  mutation UpdateTeamRoles($uuid: ID!, $roleIds: [ID!]!) {
    updateTeamRoles(input: { uuid: $uuid, roleIds: $roleIds }) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
