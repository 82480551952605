import React from 'react';

import { Translate } from '../../../../../../../helpers/Translate';

import { materialsKeys } from '../../../../../../../locales/keys';

function ThreeDStockIndexPageMaterialsCategoriesNavHead() {
  return (
    <div className="-mx-4 mb-1">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0">
        <h6 className="flex-1 font-semibold uppercase px-2">
          <Translate id={materialsKeys.plural} />
        </h6>
      </div>
    </div>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavHead;
