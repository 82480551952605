import { FetchSectionsSortTypes } from './sectionsTypes';

export const INITIAL_SECTIONS_FILTERS = {};
export const INITIAL_SECTIONS_PAGE = 1;
export const INITIAL_SECTIONS_SORT = [FetchSectionsSortTypes.CREATED_AT_DESC];
export const INITIAL_SECTIONS_LIMIT = 50;

export enum SectionsPermissions {
  READ_SECTIONS_INDEX_PAGE = 'read_sections_index_page',
  READ_ADMIN_MENU_SECTIONS_LINK = 'read_admin_menu_sections_link'
}
