import { FetchReportsSortTypes } from './reportsTypes';

export const INITIAL_REPORTS_FILTERS = {};
export const INITIAL_REPORTS_PAGE = 1;
export const INITIAL_REPORTS_SORT = [FetchReportsSortTypes.ID_DESC];
export const INITIAL_REPORTS_LIMIT = 50;

export const enum ReportsPermissions {
  READ_ADMIN_MENU_REPORTS_LINK = 'read_admin_menu_reports_link',
  READ_REPORTS_INDEX_PAGE = 'read_reports_index_page',
  READ_PROJECTS_GLOBAL_REPORT = 'read_projects_global_report',
  READ_PROJECTS_GLOBAL_LITE1_REPORT = 'read_projects_global_lite1_report',
  READ_PROJECTS_GLOBAL_LITE2_REPORT = 'read_projects_global_lite2_report',
  READ_PROJECTS_GLOBAL_LITE3_REPORT = 'read_projects_global_lite3_report',
  READ_TASKS_INDEX_REPORT = 'read_tasks_index_report',
  READ_TASKS_GLOBAL_REPORT = 'read_tasks_global_report',
  READ_TASKS_GLOBAL_LITE_REPORT = 'read_tasks_global_lite_report',
  READ_TASKS_GLOBAL_LITE1_REPORT = 'read_tasks_global_lite1_report',
  READ_TASKS_GLOBAL_LITE2_REPORT = 'read_tasks_global_lite2_report',
  READ_TASKS_GLOBAL_LITE3_REPORT = 'read_tasks_global_lite3_report',
  READ_CLIENTS_GLOBAL_REPORT = 'read_clients_global_report',
  READ_CLIENTS_GLOBAL_LITE_REPORT = 'read_clients_global_lite_report',
  READ_CLIENTS_GLOBAL_LITE1_REPORT = 'read_clients_global_lite1_report',
  READ_CLIENTS_PULSE_REPORT = 'read_clients_pulse_report',
  READ_WORKERS_PULSE_REPORT = 'read_workers_pulse_report',
  READ_WORKERS_GLOBAL_REPORT = 'read_workers_global_report',
  READ_RANGE_REVENUE_RECOGNITION_REPORT = 'read_range_revenue_recognition_report',
  READ_TOTAL_CLIENT_PAYMENTS_REPORT = 'read_total_client_payments_report',
  READ_CLIENTS_REVENUE_REPORT = 'read_clients_revenue_report',
  READ_WORKERS_REVENUE_REPORT = 'read_workers_revenue_report',
  READ_CLIENT_TO_WORKER_PAYMENTS_REPORT = 'read_client_to_worker_payments_report',
  READ_RECEIVED_PAYMENTS_REPORT = 'read_received_payments_report',
  READ_WORKERS_TIME_REPORT = 'read_workers_time_report',
  READ_REVENUE_SHARE_REPORT = 'read_revenue_share_report',
  READ_CLIENTS_AVERAGE_PAYMENT_FREQUENCY_REPORT = 'read_clients_average_payment_frequency_report',
  READ_WORKERS_DOWNLOADS_REPORT = 'read_workers_downloads_report',
  READ_WORKERS_DOWNLOADS_DETAILED_REPORT = 'read_workers_downloads_detailed_report',
  READ_GLOBAL_PROJECTS_BALANCE_REPORT = 'read_global_projects_balance_report',
  READ_GLOBAL_TASKS_BALANCE_REPORT = 'read_global_tasks_balance_report',
  READ_CLIENTS_BALANCES_REPORT = 'read_clients_balances_report',
  READ_WORKERS_BALANCES_REPORT = 'read_workers_balances_report',
  READ_SUSPENDED_TASKS_REPORT = 'read_suspended_tasks_report',
  READ_GLOBAL_PROJECTS_DEBT_REPORT = 'read_global_projects_debt_report',
  READ_GLOBAL_TASKS_DEBT_REPORT = 'read_global_tasks_debt_report',
  READ_GLOBAL_CLIENTS_DEBT_REPORT = 'read_global_clients_debt_report',
  READ_GLOBAL_WORKERS_DEBT_REPORT = 'read_global_workers_debt_report',
  READ_GLOBAL_TEAMS_AND_CLIENTS_REPORT = 'read_global_teams_and_clients_report',
  READ_TASKS_ACCOUNT_RECEIVABLE_REPORT = 'read_tasks_account_receivable_report',
  READ_RFA_REPORT = 'read_rfa_report',
  READ_LIBRARY_USAGE_REPORT = 'read_library_usage_report',
  READ_CLIENTS_FILES_USAGE_REPORT = 'read_clients_files_usage_report',
  READ_CLIENTS_REQUIREMENTS_FILES_REPORT = 'read_clients_requirements_files_report',
  READ_BLOCKED_USERS_ALLOCATIONS_REPORT = 'read_blocked_users_allocations_report',
  READ_BURNED_TASKS_REPORT = 'read_burned_tasks_report',
  READ_TASKS_CHECK_LISTS_REPORT = 'read_tasks_check_lists_report',
  READ_REPLY_RATE_REPORT = 'read_reply_rate_report',
  READ_POSTPONED_TERMS_REPORT = 'read_postponed_terms_report',
  READ_TAGGED_TASKS_REPORT = 'read_tagged_tasks_report',
  READ_ACTQ_REPORT = 'read_actq_report',
  READ_MANAGERS_DYNAMICS_REPORT = 'read_managers_dynamics_report',
  READ_DOUBLE_DISTRIBUTIONS_REPORT = 'read_double_distributions_report',
  READ_MINUS_DISTRIBUTIONS_REPORT = 'read_minus_distributions_report',
  READ_CREATE_TASKS_INDEX_REPORT_BUTTON = 'read_create_tasks_index_report_button',
  READ_USER_REPORTS = 'read_user_reports'
}
