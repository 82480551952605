import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  ModelBrand,
  FetchItemGqlQuery,
  FetchItemCacheKey,
  NanoID
} from '../../../types';
import { ImageFile } from '../../images/imagesTypes';
import { UserAvatarLinkUser } from '../../common/helpers/UserAvatarLink';

export type EmailTemplateID = ModelBrand<ID, 'EmailTemplateID'>;
export type EmailTemplateUUID = ModelBrand<UUID, 'EmailTemplateUUID'>;
export type EmailTemplateName = string;
export type EmailTemplateBody = string;
export type EmailTemplateBodyEn = string;
export type EmailTemplateBodyUk = string;
export type EmailTemplateLocalizedName = string;
export type EmailTemplateLocalizedBody = string;
export type EmailTemplateDescription = string;
export type EmailTemplateKey = string;
export type EmailTemplateSubject = string;
export type EmailTemplateSubjectEn = string;
export type EmailTemplateSubjectUk = string;

export type EmailTemplateCreatedAt = CreatedAt;
export type EmailTemplateUpdatedAt = UpdatedAt;

export type EmailTemplateUserFullName = string;
export type EmailTemplateUserNanoID = ModelBrand<
  NanoID,
  'EmailTemplateUserNanoID'
>;
export type EmailTemplateUserFile = ImageFile;
export type EmailTemplateUserAvatar = UserAvatarLinkUser;

export type FetchEmailTemplateGqlQuery = FetchItemGqlQuery;
export type FetchEmailTemplateCacheKey = FetchItemCacheKey;

export const enum EmailTemplatesFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  KEY = 'key',
  DESCRIPTION = 'description',
  BODY = 'body',
  BODY_EN = 'bodyEn',
  BODY_UK = 'bodyUk',
  SUBJECT = 'subject',
  SUBJECT_EN = 'subjectEn',
  SUBJECT_UK = 'subjectUk',
  CREATED_AT = 'createdAt'
}
