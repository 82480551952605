import { gql } from 'graphql-request';

export const UPDATE_TEAM_FILES_STORAGE_QUERY = gql`
  mutation UpdateTeamFilesStorage($uuid: ID!, $filesStorage: String!) {
    updateTeamFilesStorage(
      input: { uuid: $uuid, filesStorage: $filesStorage }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
