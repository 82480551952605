import { gql } from 'graphql-request';

export const UPDATE_TEAM_SCOPE_OF_WORKS_QUERY = gql`
  mutation UpdateTeamScopeOfWorks($uuid: ID!, $scopeOfWorkIds: [ID!]!) {
    updateTeamScopeOfWorks(
      input: { uuid: $uuid, scopeOfWorkIds: $scopeOfWorkIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
