import React from 'react';
import cl from 'classnames';

import { useMenu } from '../../../../../app/hooks/useMenu';

import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../helpers/Translate';

import { words } from '../../../../../locales/keys';

interface MenuMajorLinkProps {
  i18nText: string;
  href: string;
  active?: boolean;
}

function MenuMajorLink({ i18nText, href, active }: MenuMajorLinkProps) {
  const { closeSecondaryMenu } = useMenu();

  return (
    <div className="-mx-4 mb-1">
      <div
        className={cl(
          'flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500',
          active
            ? 'bg-opacity-25 hover:bg-opacity-30'
            : 'bg-opacity-0 hover:bg-opacity-20'
        )}
      >
        <h6 className="flex-1 font-semibold uppercase px-2">
          <Translate id={i18nText} />
        </h6>
        <NextLinkHelper
          href={href}
          className="opacity-0 group-hover:opacity-100 focus:opacity-100 focus:outline-none hover:underline text-sm"
          onClick={closeSecondaryMenu}
        >
          <Translate id={words.viewAll} />
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>
      </div>
    </div>
  );
}

export default MenuMajorLink;
