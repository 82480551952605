import * as yup from 'yup';
import toNumber from 'lodash/toNumber';

import { CompanyWithdrawFormFields } from '../CompanyWithdrawForm.types';

import { formsErrors } from '../../../../../../locales/keys';

export const companyWithdrawFormShema = (max: number) =>
  yup.object({
    [CompanyWithdrawFormFields.AMOUNT]: yup
      .number()
      .transform((_, value) => (value === '' ? undefined : toNumber(value)))
      .min(20, formsErrors.amount.minAmountPayoneerTwenty)
      .max(max, formsErrors.amount.amountNoMoreThanYourAccount)
      .required(formsErrors.required),
    [CompanyWithdrawFormFields.PAYONEER_ACCOUNT_TYPE]: yup
      .string()
      .required(formsErrors.required)
  });
