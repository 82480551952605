import React from 'react';

import { UserUUID } from '../../../../../usersTypes';

import {
  UPDATE_USER_IMAGE_QUERY,
  UpdateUserImageQueryResponse
} from '../../../../../queries/updateUserImage.query';

import { useUpdateUserImage } from '../../../../../hooks/useUpdateUserImage';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  UserAvatar,
  UserAvatarUser
} from '../../../../../../common/helpers/UserAvatar';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { DropzoneImageModalButton } from '../../../../../../../helpers/buttons/DropzoneImageModalButton';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { UsersPermissions } from '../../../../../usersConstants';
import { UserCache } from '../../../../../UserCache';
import { profilesKeys } from '../../../../../../../locales/keys';

export type SingleUserProfileSecondaryMenuWrapperHeadUserAvatarUser = {
  uuid: UserUUID;
} & UserAvatarUser;

interface SingleUserProfileSecondaryMenuWrapperHeadUserAvatarProps {
  user: SingleUserProfileSecondaryMenuWrapperHeadUserAvatarUser;
}

function SingleUserProfileSecondaryMenuWrapperHeadUserAvatar({
  user
}: SingleUserProfileSecondaryMenuWrapperHeadUserAvatarProps) {
  const currentUser = useCurrentUser();

  const selfProfile = user.uuid === currentUser.uuid;

  const { updateUserImage, updateUserImageErrorMessage } =
    useUpdateUserImage<UpdateUserImageQueryResponse>({
      action: 'updateUserImage',
      query: UPDATE_USER_IMAGE_QUERY,
      cacheKeys: [UserCache.showCacheKey()],
      uuid: user.uuid
    });

  return (
    <div className="w-10 h-10 sm:w-12 sm:h-12 rounded bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900 flex items-center justify-center overflow-hidden relative self-center">
      <UserAvatar className="w-full h-full block object-cover" user={user} />

      <CheckPermissions
        action={
          selfProfile
            ? UsersPermissions.CHANGE_SELF_IMAGE_FIELD
            : UsersPermissions.CHANGE_USER_IMAGE_FIELD
        }
      >
        <DropzoneImageModalButton
          type="images"
          className="absolute inset-0 transition-all hover:bg-black opacity-0 hover:opacity-100 hover:bg-opacity-50 flex items-center justify-center cursor-pointer text-white"
          buttonText=""
          i18nTitle={profilesKeys.editProfilePicture}
          onSubmit={updateUserImage}
          submitErrorMessage={updateUserImageErrorMessage}
          resetOnClose
          maxFiles={1}
          icon={IconsEnum.UPLOAD_SOLID}
        />
      </CheckPermissions>
    </div>
  );
}

export default SingleUserProfileSecondaryMenuWrapperHeadUserAvatar;
