import { PayoneerV4PayoutID } from './payoneerV4PayoutsTypes';

const baseUrl = 'payoneer_v4_payouts';

export class PayoneerV4PayoutBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static performRequest(payoneerV4PayoutId: PayoneerV4PayoutID) {
    return `${baseUrl}/${payoneerV4PayoutId}/perform_request`;
  }

  static fetchStatus(payoneerV4PayoutId: PayoneerV4PayoutID) {
    return `${baseUrl}/${payoneerV4PayoutId}/fetch_status`;
  }
}
