import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MenuDropdownWithLinks } from '../MenuDropdownWithLinks';

import { menuKeys } from '../../../../../locales/keys';

import {
  accessDropdownItemsData,
  accessPermissions,
  contentDropdownItemsData,
  contentPermissions,
  financeDropdownItemsData,
  financePermissions,
  reportsDropdownItemsData,
  reportsPermissions
} from './DefaultSecondaryMenuAdmin.links';

function DefaultSecondaryMenuAdmin() {
  return (
    <SecondaryMenuWrapper i18nTitle={menuKeys.tabs.admin}>
      <div className="p-4">
        <CheckPermissions actions={accessPermissions}>
          <MenuDropdownWithLinks
            icon={IconsEnum.KEY}
            i18nText={menuKeys.links.access}
            dropdownItemsData={accessDropdownItemsData}
          />
        </CheckPermissions>
        <CheckPermissions actions={financePermissions}>
          <MenuDropdownWithLinks
            icon={IconsEnum.CASH}
            i18nText={menuKeys.links.finance}
            dropdownItemsData={financeDropdownItemsData}
          />
        </CheckPermissions>
        <CheckPermissions actions={contentPermissions}>
          <MenuDropdownWithLinks
            icon={IconsEnum.NEWSPAPER_SOLID}
            i18nText={menuKeys.links.content}
            dropdownItemsData={contentDropdownItemsData}
          />
        </CheckPermissions>
        <CheckPermissions actions={reportsPermissions}>
          <MenuDropdownWithLinks
            icon={IconsEnum.DOCUMENT_REPORT_SOLID}
            i18nText={menuKeys.links.reports}
            dropdownItemsData={reportsDropdownItemsData}
          />
        </CheckPermissions>
      </div>
    </SecondaryMenuWrapper>
  );
}

export default DefaultSecondaryMenuAdmin;
