export enum DefaultSmartContractRulesPermissions {
  READ_ADMIN_MENU_SMART_CONTRACT_FEES_LINK = 'read_admin_menu_smart_contract_fees_link',
  READ_SMART_CONTRACT_FEES_INDEX_PAGE = 'read_smart_contract_fees_index_page',
  READ_SMART_CONTRACT_FEES_TRANSACTIONS_FEE_BLOCK = 'read_smart_contract_fees_transactions_fee_block',
  READ_SMART_CONTRACT_FEES_REVISION_BLOCK = 'read_smart_contract_fees_revision_block',
  READ_SMART_CONTRACT_FEES_HOLD_RATE_BLOCK = 'read_smart_contract_fees_hold_rate_block',
  READ_DEFAULT_SMART_CONTRACT_FEES_SELECT_FIELD = 'read_default_smart_contract_fees_select_field',
  CHANGE_DEFAULT_SMART_CONTRACT_FEES_SELECT_FIELD = 'change_default_smart_contract_fees_select_field',
  READ_DEFAULT_SMART_CONTRACT_FEES_NAME_FIELD = 'read_default_smart_contract_fees_name_field',
  CHANGE_DEFAULT_SMART_CONTRACT_FEES_NAME_FIELD = 'change_default_smart_contract_fees_name_field',
  READ_DEFAULT_SMART_CONTRACT_FEES_VALUE_FIELD = 'read_default_smart_contract_fees_value_field',
  CHANGE_DEFAULT_SMART_CONTRACT_FEES_VALUE_FIELD = 'change_default_smart_contract_fees_value_field',
  READ_DEFAULT_SMART_CONTRACT_FEES_DELETE_BUTTON = 'read_default_smart_contract_fees_delete_button',
  READ_DEFAULT_SMART_CONTRACT_FEES_CREATE_BUTTON = 'read_default_smart_contract_fees_create_button',
  READ_SMART_CONTRACT_FEES_CONTRACTORS_BLOCK = 'read_smart_contract_fees_contractors_block',
  READ_DEFAULT_SMART_CONTRACT_CONTRACTORS_SELECT_FIELD = 'read_default_smart_contract_contractors_select_field',
  CHANGE_DEFAULT_SMART_CONTRACT_CONTRACTORS_SELECT_FIELD = 'change_default_smart_contract_contractors_select_field',
  READ_DEFAULT_SMART_CONTRACT_CONTRACTORS_NAME_FIELD = 'read_default_smart_contract_contractors_name_field',
  CHANGE_DEFAULT_SMART_CONTRACT_CONTRACTORS_NAME_FIELD = 'change_default_smart_contract_contractors_name_field',
  READ_DEFAULT_SMART_CONTRACT_CONTRACTORS_DELETE_BUTTON = 'read_default_smart_contract_contractors_delete_button',
  READ_DEFAULT_SMART_CONTRACT_CONTRACTORS_CREATE_BUTTON = 'read_default_smart_contract_contractors_create_button'
}

export const INITIAL_DEFAULT_SMART_CONTRACT_RULES_PAGE = 1;
export const INITIAL_DEFAULT_SMART_CONTRACT_RULES_LIMIT = 50;

export const enum DefaultSmartContractRuleFields {
  NAME = 'name',
  VALUE = 'value',
  PRESENCE_TYPE = 'presenceType'
}
