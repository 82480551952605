import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  FetchItemsGqlQuery,
  FetchItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemGqlQuery,
  DeleteItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlStatus,
  DeleteItemGqlStatus,
  ItemGqlError,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsErrorMessage,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSort,
  FetchItemsTotalCount,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSortItems,
  FetchItemsShowItem,
  FetchItemsEditItem
} from '../../types';

import {
  UserUUID,
  UserFullName,
  UserImageUUID,
  UserImageFile,
  UserNanoID
} from '../users/usersTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';

export type SectionID = ID;
export type SectionUUID = UUID;
export type SectionName = string;
export type SectionFilterParts = string[];
export type SectionCreatedAt = CreatedAt;
export type SectionUpdatedAt = UpdatedAt;

export type SectionUserUUID = UserUUID;
export type SectionUserNanoID = UserNanoID;
export type SectionUserFullName = UserFullName;
export type SectionUserImageUUID = UserImageUUID;
export type SectionUserImageFile = UserImageFile;

export enum SectionFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export const sectionNameField = 'name'; // SectionFields.NAME

export interface FetchSectionsFilters {
  name?: {
    ilike?: string;
  };
  filterParts?: string[];
  createdAt?: string;
}

export enum FetchSectionsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchSectionsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSectionsGqlQuery = FetchItemsGqlQuery;
export type FetchSectionGqlQuery = FetchItemGqlQuery;
export type CreateSectionGqlQuery = CreateItemGqlQuery;
export type UpdateSectionGqlQuery = UpdateItemGqlQuery;
export type DeleteSectionGqlQuery = DeleteItemGqlQuery;

export type CreateSectionGqlStatus = CreateItemGqlStatus;
export type UpdateSectionGqlStatus = UpdateItemGqlStatus;
export type DeleteSectionGqlStatus = DeleteItemGqlStatus;
export type SectionGqlError = ItemGqlError;

export type FetchSectionsCacheKey = FetchItemsCacheKey;
export type FetchSectionCacheKey = FetchItemCacheKey;

export type FetchSectionsFetched = FetchItemsFetched;
export type FetchSectionsErrorMessage = FetchItemsErrorMessage;
export type FetchSectionsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchSectionsPage = FetchItemsPage;
export type FetchSectionsLimit = FetchItemsLimit;
export type FetchSectionsSort = FetchItemsSort;
export type FetchSectionsTotalCount = FetchItemsTotalCount;
export type FetchSectionsSortSections = FetchItemsSortItems;
export type FetchSectionsPaginateSections = FetchItemsPaginateItems;
export type FetchSectionsPrefetchSections = FetchItemsPrefetchItems;
export type FetchSectionsShowSection = FetchItemsShowItem;
export type FetchSectionsEditSection = FetchItemsEditItem;

export type FetchSectionsChecked = Checked;
export type FetchSectionsCheckedHash = CheckedHashItem;
export type FetchSectionsOnSetCheckedIds = OnSetCheckedIds;
export type FetchSectionsCheckedAll = CheckedAll;
export type FetchSectionsOnCheckAll = OnCheckAll;
