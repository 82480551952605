import { BffApiCreateItemError } from '../../../../types';

import {
  PayoneerV4PayoutInvoiceID,
  CreatePayoneerV4PayoutCacheKeys,
  PayoneerV4PayoutProgramKey,
  PayoneerV4PayoutAmount,
  PayoneerV4PayoutDescription,
  PayoneerV4PayoutPayeeID
} from '../../payoneerV4PayoutsTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { PayoneerV4PayoutBffUrl } from '../../PayoneerV4PayoutsBffUrl';

interface CreatePayoneerV4PayoutOptions {
  cacheKeys?: CreatePayoneerV4PayoutCacheKeys;
}

export interface CreatePayoneerV4PayoutInput {
  payoneerV4Payout: {
    program_key: PayoneerV4PayoutProgramKey;
    amount: PayoneerV4PayoutAmount;
    description: PayoneerV4PayoutDescription;
    payee_id: PayoneerV4PayoutPayeeID;
  };
}

export type CreatePayoneerV4PayoutError = BffApiCreateItemError;

interface CreatePayoneerV4PayoutResponseType {
  id: PayoneerV4PayoutInvoiceID;
}

function useCreatePayoneerV4Payout({
  cacheKeys
}: CreatePayoneerV4PayoutOptions = {}) {
  const {
    createQuery,
    createQueryData,
    createQueryReset,
    createQueryErrorMessage,
    createQueryLoading
  } = useBffCreateQuery<
    CreatePayoneerV4PayoutInput,
    CreatePayoneerV4PayoutResponseType,
    CreatePayoneerV4PayoutError,
    CreatePayoneerV4PayoutResponseType
  >({ url: PayoneerV4PayoutBffUrl.create(), cacheKeys });

  return {
    createPayoneerV4PayoutData: createQueryData,
    createPayoneerV4PayoutLoading: createQueryLoading,
    createPayoneerV4PayoutErrorMessage: createQueryErrorMessage,
    createPayoneerV4PayoutReset: createQueryReset,
    createPayoneerV4Payout: createQuery
  };
}

export default useCreatePayoneerV4Payout;
