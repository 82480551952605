import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { HexColor } from '../../../../../../../types';

import { useMenu } from '../../../../../../../app/hooks/useMenu';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../helpers/Icon';

interface MenuDropdownWithLinksItemProps {
  href: string;
  i18nText: string;
  active: boolean;
  color?: HexColor;
}

function MenuDropdownWithLinksItem({
  href,
  i18nText,
  active,
  color
}: MenuDropdownWithLinksItemProps) {
  const { closeSecondaryMenu } = useMenu();

  return (
    <div className="-mx-4">
      <div
        className={cl(
          'flex items-center group relative pl-8 pr-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500',
          active
            ? 'bg-opacity-25 hover:bg-opacity-30'
            : 'bg-opacity-0 hover:bg-opacity-20'
        )}
      >
        <NextLinkHelper
          className="focus:outline-none flex-1 px-2 text-sm"
          href={href}
          onClick={closeSecondaryMenu}
        >
          <Translate id={i18nText} />
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>

        {color ? (
          <div className="flex items-center" style={{ color: color }}>
            <Icon icon={IconsEnum.LABEL_SOLID} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MenuDropdownWithLinksItem;
