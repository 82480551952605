import {
  UserPayoneerId,
  UserPayoneerIdPe,
  UserPayoneerIdAvltd,
  UserPayoneerIdPeAvltd,
  UserPayoneerType,
  UserFields
} from '../../usersTypes';
import { PayoneerAccountTypes } from '../../../../types';

interface GetUserPayoneerFieldValueByTypeUser {
  payoneerId: UserPayoneerId;
  payoneerIdPe: UserPayoneerIdPe;
  payoneerIdAvltd: UserPayoneerIdAvltd;
  payoneerIdPeAvltd: UserPayoneerIdPeAvltd;
  payoneerIdType: UserPayoneerType;
}

function getUserPayoneerFieldValueByType(
  user?: GetUserPayoneerFieldValueByTypeUser
) {
  switch (user?.payoneerIdType) {
    case PayoneerAccountTypes.PAYONEER_ID:
      return user?.[UserFields.PAYONEER_ID];
    case PayoneerAccountTypes.PAYONEER_ID_PE:
      return user?.[UserFields.PAYONEER_ID_PE];
    case PayoneerAccountTypes.PAYONEER_ID_AVLTD:
      return user?.[UserFields.PAYONEER_ID_AVLTD];
    case PayoneerAccountTypes.PAYONEER_ID_PE_AVLTD:
      return user?.[UserFields.PAYONEER_ID_PE_AVLTD];

    default:
      return null;
  }
}

export default getUserPayoneerFieldValueByType;
