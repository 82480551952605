import { gql } from 'graphql-request';

export const UPDATE_TEAM_REAL_DECLINE_REASONS_QUERY = gql`
  mutation UpdateTeamRealDeclineReasons(
    $uuid: ID!
    $realDeclineReasonIds: [ID!]!
  ) {
    updateTeamRealDeclineReasons(
      input: { uuid: $uuid, realDeclineReasonIds: $realDeclineReasonIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
