import { I18nText } from '../../../../../types';
import {
  FetchLifestylesFilters,
  LifestyleClientID,
  LifestylesFiltersNavBasePath
} from '../../../../lifestyles/lifestylesTypes';

import { useThreeDStockLifestylesCategories } from '../../../hooks/useThreeDStockLifestylesCategories';

import { ThreeDStockIndexPageLifestylesCategoriesNavBody } from './components/ThreeDStockIndexPageLifestylesCategoriesNavBody';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface ThreeDStockIndexPageLifestylesCategoriesNavProps {
  allCustomI18nText?: I18nText;
  clientIds?: LifestyleClientID[];
  filtersNavBasePath: LifestylesFiltersNavBasePath;
  isShowAll?: boolean;
  lifestylesFilters?: FetchLifestylesFilters;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageLifestylesCategoriesNav({
  allCustomI18nText,
  clientIds,
  filtersNavBasePath,
  isShowAll,
  lifestylesFilters,
  withNavHead
}: ThreeDStockIndexPageLifestylesCategoriesNavProps) {
  const {
    lifestyleCategoriesWithParents,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData,
    parentCategories
  } = useThreeDStockLifestylesCategories({ clientIds, lifestylesFilters });

  return (
    <div className="p-4">
      <AlertMessage message={lifestyleCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          lifestyleCategoriesIsPlaceholderData || lifestyleCategoriesFetched
        }
      >
        <ThreeDStockIndexPageLifestylesCategoriesNavBody
          allCustomI18nText={allCustomI18nText}
          filtersNavBasePath={filtersNavBasePath}
          isShowAll={isShowAll}
          lifestyleCategories={lifestyleCategoriesWithParents}
          parentCategories={parentCategories}
          withNavHead={withNavHead}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
