import { I18nText } from '../../../../../../../types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategories } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { ThreeDStockIndexPageMaterialsCategoriesNavHead } from '../ThreeDStockIndexPageMaterialsCategoriesNavHead';
import { ThreeDStockIndexPageMaterialsCategoriesNavList } from '../ThreeDStockIndexPageMaterialsCategoriesNavList';

interface ThreeDStockIndexPageMaterialsCategoriesNavBodyProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  isShowAll?: boolean;
  materialCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategories;
  parentCategories: ThreeDStockIndexPageMaterialsCategoriesNavCategories;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageMaterialsCategoriesNavBody({
  allCustomI18nText,
  filtersNavBasePath,
  isShowAll,
  materialCategories,
  parentCategories,
  withNavHead
}: ThreeDStockIndexPageMaterialsCategoriesNavBodyProps) {
  return (
    <>
      {withNavHead && <ThreeDStockIndexPageMaterialsCategoriesNavHead />}

      <ThreeDStockIndexPageMaterialsCategoriesNavList
        allCustomI18nText={allCustomI18nText}
        filtersNavBasePath={filtersNavBasePath}
        isShowAll={isShowAll}
        materialCategories={materialCategories}
        parentCategories={parentCategories}
      />
    </>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavBody;
