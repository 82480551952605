import {
  CreatedAt,
  CreateItemGqlError,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DateFilterType,
  DeleteItemGqlError,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  NanoID,
  TextFilterType,
  UpdatedAt,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID
} from '../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  UserBlocked,
  UserClient,
  UserCurrentTeamNanoID,
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserNDA,
  UserRoleName,
  UserUUID
} from '../users/usersTypes';
import { UpdateItemsGqlQuery } from '../../types/updateItemsTypes';

export const enum ChatGptPromptFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  DESCRIPTION = 'description',
  KIND = 'kind',
  APPLICABLE_TO = 'applicableTo',
  DESTINATION = 'destination',
  PROMPT = 'prompt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  USER_ID = 'userId',
  USER_UUID = 'userUuid'
}

export const enum FetchChatGptPromptsSortableFields {
  ID = 'id',
  UUID = 'uuid',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  DELETED_AT = 'deletedAt'
}

export const enum FetchChatGptPromptsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UUID_ASC = 'UUID_ASC',
  UUID_DESC = 'UUID_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC'
}

export enum FetchChatGptPromptsFiltersFields {
  CREATED_AT = 'createdAt',
  USER_ID = 'userId.in'
}

export interface FetchChatGptPromptsFilters {
  createdAt?: DateFilterType;
  userId?: IdFilterType;
}

export const enum ChatGptPromptKindEnum {
  ATTRIBUTION = 'attribution',
  CHAT_GPT = 'chat_gpt'
}

export const enum ChatGptPromptApplicableToEnum {
  ALL_MESSAGES_FROM_CLIENTS = 'all_messages_from_clients',
  TEXT_BOX = 'text_box'
}

export const enum ChatGptPromptDestinationEnum {
  API_DISCUSSISON_MESSAGE = 'api_discussison_message',
  SENT_MESSAGE = 'sent_message'
}

export type ChatGptPromptID = ID;
export type ChatGptPromptUUID = UUID;
export type ChatGptPromptName = string;
export type ChatGptPromptDescription = string;
export type ChatGptPromptKind = string; // ChatGptPromptKindEnum;
export type ChatGptPromptApplicableTo = string; // ChatGptPromptApplicableToEnum;
export type ChatGptPromptDestination = string; // ChatGptPromptDestinationEnum;
export type ChatGptPromptPrompt = string;
export type ChatGptPromptCreatedAt = CreatedAt;
export type ChatGptPromptUpdatedAt = UpdatedAt;
export type ChatGptPromptUserID = UserID;
export type ChatGptPromptUserUUID = UserUUID;
export type ChatGptPromptUserNanoID = UserNanoID;
export type ChatGptPromptUserClient = UserClient;
export type ChatGptPromptUserFullName = UserFullName;
export type ChatGptPromptUserImageUUID = UserImageUUID;
export type ChatGptPromptUserImageFile = UserImageFile;
export type ChatGptPromptUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type FetchChatGptPromptsGqlQuery = FetchItemsGqlQuery;
export type FetchChatGptPromptGqlQuery = FetchItemGqlQuery;

export type FetchChatGptPromptCacheKey = FetchItemCacheKey;
export type FetchChatGptPromptsCacheKey = FetchItemsCacheKey;

export type CreateChatGptPromptGqlError = CreateItemGqlError;
export type CreateChatGptPromptGqlQuery = CreateItemGqlQuery;
export type CreateChatGptPromptGqlStatus = CreateItemGqlStatus;

export type UpdateChatGptPromptGqlQuery = UpdateItemGqlQuery;
export type UpdateChatGptPromptGqlStatus = UpdateItemGqlStatus;

export type DeleteChatGptPromptGqlQuery = DeleteItemGqlQuery;
export type DeleteChatGptPromptGqlStatus = DeleteItemGqlStatus;
export type DeleteChatGptPromptGqlError = DeleteItemGqlError;

export type FetchChatGptPromptsFetched = FetchItemsFetched;
export type FetchChatGptPromptsErrorMessage = FetchItemsErrorMessage;
export type FetchChatGptPromptsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchChatGptPromptsPage = FetchItemsPage;
export type FetchChatGptPromptsLimit = FetchItemsLimit;
export type FetchChatGptPromptsSort = FetchItemsSort; // FetchChatGptPromptsSortTypes[];
export type FetchChatGptPromptsTotalCount = FetchItemsTotalCount;
export type FetchChatGptPromptsFilterChatGptPrompts =
  FetchItemsFilterItems<FetchChatGptPromptsFilters>;
export type FetchChatGptPromptsClearChatGptPromptsFilters =
  FetchItemsClearItemsFilters;
export type FetchChatGptPromptsSortChatGptPrompts = FetchItemsSortItems;
export type FetchChatGptPromptsPaginateChatGptPrompts = FetchItemsPaginateItems;
export type FetchChatGptPromptsPrefetchChatGptPrompts = FetchItemsPrefetchItems;

export type FetchChatGptPromptsShowChatGptPrompt = FetchItemsShowItem;
export type FetchChatGptPromptsEditChatGptPrompt = FetchItemsEditItem;

export type FetchChatGptPromptsChecked = Checked;
export type FetchChatGptPromptsCheckedHash = CheckedHashItem;
export type FetchChatGptPromptsOnSetCheckedIds = OnSetCheckedIds;
export type FetchChatGptPromptsCheckedAll = CheckedAll;
export type FetchChatGptPromptsOnCheckAll = OnCheckAll;
