import React, { memo } from 'react';

import { PrimaryNavTabsEnum } from '../../../../../app/hooks/useMenu/types';

import { useThirdPersonView } from '../../../../../app/hooks/useThirdPersonView';
import { useMenu } from '../../../../../app/hooks/useMenu';

import { CompactStockSecondaryMenu } from '../CompactStockSecondaryMenu';
import { DefaultSecondaryMenuNotifications } from '../DefaultSecondaryMenuNotifications';
import { DefaultSecondaryMenuWorkspace } from '../DefaultSecondaryMenuWorkspace';
import { DefaultSecondaryMenuUsers } from '../DefaultSecondaryMenuUsers';
import { DefaultSecondaryMenuPayments } from '../DefaultSecondaryMenuPayments';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  accessPermissions,
  contentPermissions,
  DefaultSecondaryMenuAdmin,
  financePermissions,
  reportsPermissions
} from '../DefaultSecondaryMenuAdmin';

import { AppPermissions } from '../../../../../app/appConstants';

const adminPermissions = [
  ...accessPermissions,
  ...financePermissions,
  ...contentPermissions,
  ...reportsPermissions
];

function DefaultSecondaryMenu() {
  const { isThirdPersonView } = useThirdPersonView();
  const { currentPathTab } = useMenu();

  if (isThirdPersonView) {
    return null;
  }

  if (currentPathTab === PrimaryNavTabsEnum.DASHBOARD) {
    return null;
  }

  if (currentPathTab === PrimaryNavTabsEnum.NOTIFICATIONS) {
    return (
      <CheckPermissions action={AppPermissions.READ_MENU_NOTIFICATIONS_TAB}>
        <DefaultSecondaryMenuNotifications />
      </CheckPermissions>
    );
  }

  if (currentPathTab === PrimaryNavTabsEnum.TASKS_AND_PROJECTS) {
    return (
      <CheckPermissions
        action={AppPermissions.READ_MENU_TASKS_AND_PROJECTS_TAB}
      >
        <DefaultSecondaryMenuWorkspace />
      </CheckPermissions>
    );
  }

  if (currentPathTab === PrimaryNavTabsEnum.STOCK) {
    return (
      <CheckPermissions action={AppPermissions.READ_MENU_STOCK_TAB}>
        <CompactStockSecondaryMenu />
      </CheckPermissions>
    );
  }

  if (currentPathTab === PrimaryNavTabsEnum.USERS) {
    return (
      <CheckPermissions action={AppPermissions.READ_MENU_USERS_TAB}>
        <DefaultSecondaryMenuUsers />
      </CheckPermissions>
    );
  }

  if (currentPathTab === PrimaryNavTabsEnum.PAYMENTS) {
    return (
      <CheckPermissions action={AppPermissions.READ_MENU_PAYMENTS_TAB}>
        <DefaultSecondaryMenuPayments />
      </CheckPermissions>
    );
  }

  if (currentPathTab === PrimaryNavTabsEnum.ADMIN) {
    return (
      <CheckPermissions actions={adminPermissions}>
        <DefaultSecondaryMenuAdmin />
      </CheckPermissions>
    );
  }

  return null;
}

export default memo(DefaultSecondaryMenu);
