import React, { SyntheticEvent, useState, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import cl from 'classnames';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { ButtonHelper } from '../../../../../helpers/buttons/ButtonHelper';

import { MenuDropdownWithLinksItem } from './helpers/MenuDropdownWithLinksItem';

export interface MenuDropdownWithLinksItemData {
  href: string;
  i18nText: string;
  isActive: (pathname: string) => boolean;
  permission: string;
}

interface MenuDropdownWithLinksProps {
  icon: IconsEnum;
  i18nText: string;
  dropdownItemsData: MenuDropdownWithLinksItemData[];
}

function MenuDropdownWithLinks({
  icon,
  i18nText,
  dropdownItemsData
}: MenuDropdownWithLinksProps) {
  const currentUser = useCurrentUser();
  const { pathname } = useRouter();

  const [isOpen, setIsOpen] = useState(() =>
    some(dropdownItemsData, (dropdownChildData) =>
      dropdownChildData?.isActive(pathname)
    )
  );

  const childrenWithPermission = useMemo<
    MenuDropdownWithLinksItemData[]
  >(() => {
    return filter(dropdownItemsData, ({ permission }) =>
      currentUser.hasPermissions(permission)
    );
  }, [dropdownItemsData, currentUser]);

  const hasChildren = !isEmpty(childrenWithPermission);

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setIsOpen((prevVal) => !prevVal);
    },
    [setIsOpen]
  );

  if (!hasChildren) {
    return null;
  }

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <div className="pl-2 pr-1">
          <Icon icon={icon} className="h-5 w-5 flex-shrink-0" />
        </div>
        <div className="absolute left-1 inset-y-0 flex items-center">
          <Icon
            icon={IconsEnum.CHEVRON_RIGHT}
            className={cl(
              'text-current h-5 w-5 transform flex-shrink-0',
              isOpen ? 'rotate-90' : null
            )}
          />
        </div>
        <ButtonHelper
          className="flex-1 px-2 text-sm text-left focus:ring-0 focus:ring-offset-0"
          onClick={handleClick}
        >
          <Translate id={i18nText} />
          <span className="absolute inset-0" aria-hidden="true" />
        </ButtonHelper>
      </div>
      {isOpen ? (
        <ul className="mt-2 mb-4 px-4">
          {childrenWithPermission.map(({ href, i18nText, isActive }) => (
            <MenuDropdownWithLinksItem
              key={href}
              href={href}
              i18nText={i18nText}
              active={isActive(pathname)}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );
}

export default MenuDropdownWithLinks;
