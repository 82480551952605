import { useMemo } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  FetchMaterialsFilters,
  MaterialClientID
} from '../../../materials/materialsTypes';

import { useMaterialCategories } from '../../../materials/hooks/useMaterialCategories';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategory } from '../../components/menus/ThreeDStockIndexPageMaterialsCategoriesNav/ThreeDStockIndexPageMaterialsCategoriesNav.types';
import { FetchMaterialCategoriesQueryResponse } from '../../../materials/queries/fetchMaterialCategories.query';

import { MaterialCache } from '../../../materials/MaterialCache';

interface ThreeDStockMaterialsCategoriesOptions {
  clientIds?: MaterialClientID[];
  materialsFilters?: FetchMaterialsFilters;
}

function useThreeDStockMaterialsCategories({
  clientIds,
  materialsFilters
}: ThreeDStockMaterialsCategoriesOptions) {
  const {
    materialCategories,
    materialCategoriesErrorMessage,
    materialCategoriesFetched,
    materialCategoriesIsPlaceholderData
  } = useMaterialCategories({
    cacheKey: MaterialCache.categoriesCacheKey()
  });

  const materialCategoriesWithParents = useMemo<
    FetchMaterialCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      materialCategories.map((category) => category.parent)
    );

    return sortBy(
      uniqBy([...materialCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [materialCategories]);

  const parentCategories = useMemo<
    ThreeDStockIndexPageMaterialsCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(materialCategories, (category) => !category.parent),
        'localizedName'
      ),

    [materialCategories]
  );

  return {
    materialCategoriesWithParents,
    materialCategoriesErrorMessage,
    materialCategoriesFetched,
    materialCategoriesIsPlaceholderData,
    parentCategories
  };
}

export default useThreeDStockMaterialsCategories;
