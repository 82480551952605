import toNumber from 'lodash/toNumber';

import {
  UpdateGeneralLedgerCacheKeys,
  GeneralLedgerID,
  GeneralLedgerName,
  GeneralLedgerDefaultShare,
  GeneralLedgerDefaultShareType
} from '../../../../../generalLedgersTypes';
import {
  UpdateGeneralLedgerFormData,
  UpdateGeneralLedgerFormFields
} from '../../UpdateGeneralLedgerForm.types';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateGeneralLedger } from '../../../../../hooks/useUpdateGeneralLedger';

import { updateGeneralLedgerFormSchema } from './useUpdateGeneralLedgerForm.schema';
import { useEffect } from 'react';

interface UpdateGeneralLedgerFormGeneralLedger {
  id: GeneralLedgerID;
  name: GeneralLedgerName;
  defaultShare: GeneralLedgerDefaultShare;
  defaultShareType: GeneralLedgerDefaultShareType;
}

interface UpdateGeneralLedgerFormOptions {
  generalLedger: UpdateGeneralLedgerFormGeneralLedger;
  cacheKeys?: UpdateGeneralLedgerCacheKeys;
}

function useUpdateGeneralLedgerForm({
  generalLedger,
  cacheKeys = []
}: UpdateGeneralLedgerFormOptions) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue
  } = useReactHookForm<UpdateGeneralLedgerFormData>({
    defaultValues: {
      name: generalLedger?.name,
      defaultShare: generalLedger?.defaultShare?.toString() ?? '',
      invoiced: generalLedger?.defaultShareType
    },
    schema: updateGeneralLedgerFormSchema,
    isModalForm: true
  });

  useEffect(() => {
    setValue(UpdateGeneralLedgerFormFields.NAME, generalLedger?.name);
    setValue(
      UpdateGeneralLedgerFormFields.DEFAULT_SHARE,
      generalLedger?.defaultShare?.toString() ?? ''
    );
    setValue(
      UpdateGeneralLedgerFormFields.INVOICED,
      generalLedger?.defaultShareType
    );
  }, [generalLedger, setValue]);

  const {
    updateGeneralLedgerReset,
    updateGeneralLedgerLoading,
    updateGeneralLedgerErrorMessage,
    updateGeneralLedger
  } = useUpdateGeneralLedger({
    generalLedgerId: generalLedger?.id,
    cacheKeys
  });

  return {
    control,
    resetUpdateGeneralLedgerForm: resetForm,
    updateGeneralLedgerReset,
    updateGeneralLedgerErrorMessage,
    updateGeneralLedgerLoading,
    handleUpdateGeneralLedger: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        updateGeneralLedger({
          name: data.name,
          defaultShare: toNumber(data.defaultShare),
          defaultShareType: data.invoiced
        }),
      dirtyFieldsOnly: false
    }),
    registerFields: {
      registerName: register(UpdateGeneralLedgerFormFields.NAME),
      registerDefaultShare: register(
        UpdateGeneralLedgerFormFields.DEFAULT_SHARE
      )
    },
    validationErrors: {
      nameValidationError:
        errors?.[UpdateGeneralLedgerFormFields.NAME]?.message,
      defaultShareValidationError:
        errors?.[UpdateGeneralLedgerFormFields.DEFAULT_SHARE]?.message
    }
  };
}

export default useUpdateGeneralLedgerForm;
