import { Control } from 'react-hook-form';

import { TeamOwnerNanoID } from '../../../teamsTypes';
import {
  ErrorMessage,
  IsLoading,
  MoneyType,
  RegisterFormFieldType
} from '../../../../../types';
import {
  CompanyWithdrawFormData,
  CompanyWithdrawFormFields
} from './CompanyWithdrawForm.types';

import { Form } from '../../../../../helpers/Form';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../helpers/Translate';
import { CompanyPayoneerAccountSelectField } from '../../../helpers/CompanyPayoneerAccountSelectField';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CompanyWithdrawFormAmountInput } from './helpers/CompanyWithdrawFormAmountInput';

import { accountsKeys, formsFields } from '../../../../../locales/keys';

interface CompanyWithdrawFormProps {
  form: string;
  isLoading?: IsLoading;
  accountBalance: MoneyType;
  companyOwnerNanoId: TeamOwnerNanoID;
  control: Control<CompanyWithdrawFormData>;
  registerAmount: RegisterFormFieldType<HTMLInputElement>;
  amountValidationError?: ErrorMessage;
  payoneerAccountTypeValidationError?: ErrorMessage;
}

function CompanyWithdrawForm({
  form,
  isLoading,
  accountBalance,
  companyOwnerNanoId,
  control,
  registerAmount,
  amountValidationError,
  payoneerAccountTypeValidationError
}: CompanyWithdrawFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="text-2xs text-gray-500 text-center mb-1">
          <span>
            <Translate id={accountsKeys.balance} />:{' '}
          </span>
          <MoneyHelper value={accountBalance} />
        </div>

        <CompanyWithdrawFormAmountInput
          name={registerAmount.name}
          onChange={registerAmount.onChange}
          ref={registerAmount.ref}
          min={0}
          max={accountBalance}
        />

        <AlertMessage addClassName="my-2" i18nMessage={amountValidationError} />

        <CompanyPayoneerAccountSelectField
          name={CompanyWithdrawFormFields.PAYONEER_ACCOUNT_TYPE}
          control={control}
          i18nLabel={formsFields.payoneerIdLabel}
          companyOwnerNanoId={companyOwnerNanoId}
          // disabled={isLoading}
          disabled
          error={payoneerAccountTypeValidationError}
        />
      </div>
    </Form>
  );
}

export default CompanyWithdrawForm;
