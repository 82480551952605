import { FetchPagesSortTypes } from './pagesTypes';

export const INITIAL_PAGES_FILTERS = {};
export const INITIAL_PAGES_PAGE = 1;
export const INITIAL_PAGES_SORT = [FetchPagesSortTypes.CREATED_AT_DESC];
export const INITIAL_PAGES_LIMIT = 20;

export enum PagesPermissions {
  READ_PAGES_INDEX_PAGE = 'read_pages_index_page',
  READ_ADMIN_MENU_PAGES_LINK = 'read_admin_menu_pages_link',
  READ_PAGES_SEARCH_FILTERS_POPOVER = 'read_pages_search_filters_popover'
}
