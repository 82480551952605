import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoID,
  StringFilter,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemIsLoading,
  UUID
} from '../../types';

export type ItemCategoryID = ID;
export type ItemCategoryUUID = UUID;
export type ItemCategoryNanoID = NanoID;
export type ItemCategoryCreatedAt = CreatedAt;
export type ItemCategoryUpdatedAt = UpdatedAt;
export type ItemCategoryName = string;

export const enum FetchItemCategoriesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export interface FetchItemCategoriesFilters {
  name?: StringFilter;
}

export const enum FetchFinItemCategoriesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  ID_ASC = 'ID_ASC'
}

export interface FetchFinItemCategoriesFilters {
  name?: StringFilter;
}

export type FetchItemCategoriesFilterItemCategories =
  FetchItemsFilterItems<FetchItemCategoriesFilters>;

export type FetchItemCategoriesCacheKey = FetchItemsCacheKey;

export type FetchItemCategoriesGqlQueryType = FetchItemsGqlQuery;

export type FetchItemCategoriesSort = FetchItemsSort;
export type FetchItemCategoriesSortItemCategories = FetchItemsSortItems;
export type FetchItemCategoriesPage = FetchItemsPage;
export type FetchItemCategoriesLimit = FetchItemsLimit;
export type FetchItemCategoriesErrorMessage = FetchItemsErrorMessage;
export type FetchItemCategoriesFetched = FetchItemsFetched;
export type FetchItemCategoriesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchItemCategoriesPaginateItemCategories = FetchItemsPaginateItems;
export type FetchItemCategoriesPrefetchItemCategories = FetchItemsPrefetchItems;
export type FetchItemCategoriesTotalCount = FetchItemsTotalCount;

export type FetchFinItemCategoriesPage = FetchItemsPage;
export type FetchFinItemCategoriesLimit = FetchItemsLimit;

export type FetchItemCategoriesCheckedAll = CheckedAll;
export type FetchItemCategoriesOnCheckAll = OnCheckAll;
export type FetchItemCategoriesChecked = Checked;
export type FetchItemCategoriesCheckedHash = CheckedHashItem;
export type FetchItemCategoriesOnSetCheckedIds = OnSetCheckedIds;

export type FetchItemCategoriesShowItemCategory = FetchItemsShowItem;
export type FetchItemCategoriesEditItemCategory = FetchItemsEditItem;

export const enum FetchItemCategoriesSortableFields {
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export const enum ItemCategoryFields {
  NAME = 'name'
}

export type CreateItemCategoryIsLoading = CreateItemIsLoading;
export type CreateItemCategoryCacheKeys = CreateItemCacheKeys;

export type UpdateItemCategoryIsLoading = UpdateItemIsLoading;
export type UpdateItemCategoryCacheKeys = UpdateItemCacheKeys;

export type DeleteItemCategoryCacheKeys = DeleteItemCacheKeys;
