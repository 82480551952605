import React, { Fragment, useCallback, ChangeEvent } from 'react';
import cl from 'classnames';

import { Translate } from '../../../../../../../../../helpers/Translate';

import {
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlRequiredProps,
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlValue
} from './UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl.types';
import { GeneralLedgerDefaultShareTypes } from '../../../../../../../generalLedgersTypes';

interface UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlProps {
  value: UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlValue;
  onChange: (
    value: UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlValue
  ) => void;
}

function UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl({
  value,
  error,
  disabled,
  className,
  checkBoxClassName,
  id,
  required,
  i18nLabel,
  label,
  labelClassName,
  errorClassName,
  name,
  onChange
}: UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlProps &
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlRequiredProps) {
  const handleChange = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    (e) => {
      const isChecked = e?.target?.checked;
      onChange(
        isChecked
          ? GeneralLedgerDefaultShareTypes.WITH_INVOICE
          : GeneralLedgerDefaultShareTypes.WITHOUT_INVOICE
      );
    },
    [onChange]
  );
  return (
    <Fragment>
      <div className={cl(className || 'flex items-center')}>
        <input
          className={cl(checkBoxClassName || 'basic-checkbox', {
            'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:border-red-700':
              error
          })}
          disabled={disabled}
          id={id}
          name={name}
          onChange={handleChange}
          required={required}
          type="checkbox"
          checked={value === GeneralLedgerDefaultShareTypes.WITH_INVOICE}
        />

        {i18nLabel || label ? (
          <label
            htmlFor={id}
            className={cl(
              labelClassName ||
                'ml-2 block text-sm text-gray-900 dark:text-gray-100'
            )}
          >
            {i18nLabel ? <Translate id={i18nLabel} /> : label}
          </label>
        ) : null}
      </div>

      {error && (
        <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
          {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
        </p>
      )}
    </Fragment>
  );
}

export default UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl;
