import React from 'react';

import { Translate } from '../../../../../helpers/Translate';

import { I18nText } from '../../../../../types';

interface MenuTitleProps {
  i18nText: I18nText;
}

function MenuTitle({ i18nText }: MenuTitleProps) {
  return (
    <div className="-mx-4 mb-1">
      <div className="flex items-center group relative px-4 py-2">
        <h6 className="flex-1 font-semibold uppercase px-2">
          <Translate id={i18nText} />
        </h6>
      </div>
    </div>
  );
}

export default MenuTitle;
