import {
  BillStatuses,
  FetchBillsSortTypes,
  BillsFilterStatuses
} from './billsTypes';

import { billsKeys } from '../../locales/keys';

export const INITIAL_BILLS_FILTERS = {};
export const INITIAL_BILLS_PAGE = 1;
export const INITIAL_BILLS_SORT = [FetchBillsSortTypes.CREATED_AT_DESC];
export const INITIAL_BILLS_LIMIT = 50;

export const billI18nTextStatuses = {
  [BillStatuses.NOT_PAID]: billsKeys.statuses.notPaid,
  [BillStatuses.CANCELED]: billsKeys.statuses.canceled,
  [BillStatuses.PAID]: billsKeys.statuses.paid,
  [BillStatuses.PAYING]: billsKeys.statuses.paying
};

export const billsFilterI18nTextStatuses = {
  [BillsFilterStatuses.NOT_PAID]: billsKeys.statuses.notPaid,
  [BillsFilterStatuses.CANCELED]: billsKeys.statuses.canceled,
  [BillsFilterStatuses.PAID]: billsKeys.statuses.paid,
  [BillsFilterStatuses.PAYING]: billsKeys.statuses.paying
};

export const billStatusesColors = {
  [BillStatuses.NOT_PAID]:
    'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50',
  [BillStatuses.CANCELED]:
    'bg-yellow-100 text-yellow-800 dark:text-yellow-50 dark:bg-yellow-900 dark:bg-opacity-50',
  [BillStatuses.PAID]:
    'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900 dark:bg-opacity-50',
  [BillStatuses.PAYING]:
    'bg-blue-100 text-blue-800 dark:text-blue-50 dark:bg-blue-900 dark:bg-opacity-50'
};

export enum BillsPermissions {
  READ_PAYONEER_BILL_TYPE = 'read_payoneer_bill_type',
  READ_PAYONEER_CHECKOUT_BILL_TYPE = 'read_payoneer_checkout_bill_type',
  READ_2CHECKOUT_BILL_TYPE = 'read_2checkout_bill_type',
  READ_FONDY_BILL_TYPE = 'read_fondy_bill_type',
  READ_STRIPE_BILL_TYPE = 'read_stripe_bill_type',
  READ_EASYPAY_BILL_TYPE = 'read_easypay_bill_type',
  READ_INVOICE_BILL_TYPE = 'read_invoice_bill_type',
  READ_SYSTEM_BILL_TYPE = 'read_system_bill_type',
  READ_DEBIT_ACCOUNT_BILL_TYPE = 'read_debit_account_bill_type',
  READ_CANCEL_DEBIT_ACCOUNT_BILL_TYPE = 'read_cancel_debit_account_bill_type',
  READ_BONUS_BILL_TYPE = 'read_bonus_bill_type',
  READ_CANCEL_BONUS_BILL_TYPE = 'read_cancel_bonus_bill_type',
  READ_TASK_BILL_TYPE = 'read_task_bill_type',
  READ_CANCEL_TASK_BILL_TYPE = 'read_cancel_task_bill_type',
  READ_INVOICE_FORM_ALLOCABLE_USER_FIELD = 'read_invoice_form_allocable_user_field',
  READ_INVOICE_FORM_TRANSACTION_TAGS_FIELD = 'read_invoice_form_transaction_tags_field',
  READ_BILLS_TABLE_DROPDOWN_FIELD = 'read_bills_table_dropdown_field',
  READ_BILLS_CANCEL_BILL_BUTTON = 'read_bills_cancel_bill_button',
  READ_BILLS_CHECK_BILL_BUTTON = 'read_bills_check_bill_button',
  READ_BILLS_PAY_BILL_BUTTON = 'read_bills_pay_bill_button',
  READ_2CHECKOUT_SUCCESS_PAGE = 'read_2checkout_success_page',
  READ_PAYMENTS_SUCCESS_PAGE = 'read_payments_success_page',
  READ_EASYPAY_SUCCESS_PAGE = 'read_easypay_success_page',
  READ_FONDY_SUCCESS_PAGE = 'read_fondy_success_page',
  READ_BILLS_INDEX_PAGE = 'read_bills_index_page',
  READ_BILLS_PROJECT_NAME_FILTER = 'read_bills_project_name_filter',
  READ_BILLS_CREATED_AT_FILTER = 'read_bills_created_at_filter',
  READ_BILLS_AMOUNT_CENTS_FILTER = 'read_bills_amount_cents_filter',
  READ_BILLS_STATUS_FILTER = 'read_bills_status_filter',
  READ_BILLS_USER_ID_FILTER = 'read_bills_user_id_filter',
  READ_BILLS_PAYER_ID_FILTER = 'read_bills_payer_id_filter',
  READ_ADMIN_MENU_INVOICES_LINK = 'read_admin_menu_invoices_link'
}
