import * as yup from 'yup';
import toNumber from 'lodash/toNumber';
import { formsErrors } from '../../../../../../../locales/keys';

import { UpdateGeneralLedgerFormFields } from '../../UpdateGeneralLedgerForm.types';

export const updateGeneralLedgerFormSchema = yup.object({
  [UpdateGeneralLedgerFormFields.NAME]: yup
    .string()
    .nullable()
    .required(formsErrors.required),
  [UpdateGeneralLedgerFormFields.DEFAULT_SHARE]: yup
    .number()
    .transform((_, value) =>
      !value || value === '' ? undefined : toNumber(value)
    )
    .nullable()
    .required(formsErrors.required)
    .min(0, formsErrors.percent.nonNegative)
    .max(100, formsErrors.percent.lessThanOneHundred)
});
