import { FetchSentMessagesSortTypes } from './sentMessagesTypes';

export const INITIAL_SENT_MESSAGES_FILTERS = {};
export const INITIAL_SENT_MESSAGES_PAGE = 1;
export const INITIAL_SENT_MESSAGES_SORT = [
  FetchSentMessagesSortTypes.CREATED_AT_DESC
];
export const INITIAL_SENT_MESSAGES_LIMIT = 50;

export enum SentMessagesPermissions {
  READ_ADMIN_MENU_SENT_MESSAGES_LINK = 'read_admin_menu_sent_messages_link',
  READ_SENT_MESSAGES_INDEX_PAGE = 'read_sent_messages_index_page',
  READ_SENT_MESSAGES_SEARCH_FILTERS_POPOVER = 'read_sent_messages_search_filters_popover'
}
