import React from 'react';

import { SingleUserPageHeaderUser } from './SingleUserProfileSecondaryMenuWrapperHead.types';

import { SingleUserProfileSecondaryMenuWrapperHeadUserAvatar } from './components/SingleUserProfileSecondaryMenuWrapperHeadUserAvatar';
import { SingleUserProfileSecondaryMenuWrapperHeadUserInfo } from './components/SingleUserProfileSecondaryMenuWrapperHeadUserInfo';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface SingleUserProfileSecondaryMenuWrapperHeadProps {
  user: SingleUserPageHeaderUser;
  loaded: boolean;
}

function SingleUserProfileSecondaryMenuWrapperHead({
  user,
  loaded
}: SingleUserProfileSecondaryMenuWrapperHeadProps) {
  return (
    <div className="flex-shrink-0 h-16 border-b border-gray-200 dark:border-gray-700 relative">
      <div className="absolute inset-0 overflow-hidden">
        <div className="flex flex-row w-full h-full items-center px-6">
          <div className="-mx-4 flex gap-2">
            <LoadingSkeleton
              loaded={loaded}
              count={1}
              width={48}
              height={48}
              className="m-0"
              skeletonClassName="flex"
            >
              <SingleUserProfileSecondaryMenuWrapperHeadUserAvatar
                user={user}
              />
            </LoadingSkeleton>
            <div className="flex flex-col flex-1 min-w-px sm:px-2 py-0.5 gap-1">
              <LoadingSkeleton loaded={loaded} count={2} className="m-0">
                <SingleUserProfileSecondaryMenuWrapperHeadUserInfo
                  user={user}
                />
              </LoadingSkeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleUserProfileSecondaryMenuWrapperHead;
