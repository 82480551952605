import { FetchItemTypesSortTypes } from './itemTypesTypes';

export enum ItemTypesPermissions {
  READ_ADMIN_MENU_DEFAULT_ITEM_TYPES_LINK = 'read_admin_menu_default_item_types_link',
  READ_ADMIN_MENU_ITEM_TAGS_LINK = 'read_admin_menu_item_tags_link',
  READ_ADMIN_MENU_ITEM_TYPES_LINK = 'read_admin_menu_item_types_link',
  READ_ITEM_TYPES_DEFAULT_INDEX_PAGE = 'read_item_types_default_index_page',
  READ_ITEM_TYPES_INDEX_PAGE = 'read_item_types_index_page',

  READ_DEFAULT_ITEM_TYPES_TABLE_SELECTED_FIELD = 'read_default_item_types_table_selected_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_NAME_FIELD = 'read_default_item_types_table_name_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_USER_FIELD = 'read_default_item_types_table_user_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_CREATED_AT_FIELD = 'read_default_item_types_table_created_at_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_ITEM_CATEGORY_FIELD = 'read_default_item_types_table_item_category_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_PRICE_FIELD = 'read_default_item_types_table_price_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_ITEM_TAG_FIELD = 'read_default_item_types_table_item_tag_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_DROPDOWN_FIELD = 'read_deafult_item_types_table_dropdown_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_DESCRIPTION_FIELD = 'read_default_item_types_table_description_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_NEW_CLIENT_DEFAULT_AT_FIELD = 'read_default_item_types_table_new_client_default_at_field',
  READ_DEFAULT_ITEM_TYPES_TABLE_IMAGE_URL_FIELD = 'read_default_item_types_table_image_url_field',

  READ_ITEM_TYPES_TABLE_DESCRIPTION_FIELD = 'read_item_types_table_description_field',
  READ_ITEM_TYPES_TABLE_NEW_CLIENT_DEFAULT_AT_FIELD = 'read_item_types_table_new_client_default_at_field',
  READ_ITEM_TYPES_TABLE_IMAGE_URL_FIELD = 'read_item_types_table_image_url_field',
  CREATE_ITEM_TYPES_TABLE_DESCRIPTION_FIELD = 'create_item_types_table_description_field',
  CREATE_ITEM_TYPES_TABLE_NEW_CLIENT_DEFAULT_AT_FIELD = 'create_item_types_table_new_client_default_at_field',
  CREATE_ITEM_TYPES_TABLE_IMAGE_URL_FIELD = 'create_item_types_table_image_url_field',
  CHANGE_ITEM_TYPES_TABLE_DESCRIPTION_FIELD = 'change_item_types_table_description_field',
  CHANGE_ITEM_TYPES_TABLE_NEW_CLIENT_DEFAULT_AT_FIELD = 'change_item_types_table_new_client_default_at_field',
  CHANGE_ITEM_TYPES_TABLE_IMAGE_URL_FIELD = 'change_item_types_table_image_url_field',
  READ_ITEM_TYPES_TABLE_SELECTED_FIELD = 'read_item_types_table_selected_field',
  READ_ITEM_TYPES_TABLE_NAME_FIELD = 'read_item_types_table_name_field',
  READ_ITEM_TYPES_TABLE_USER_FIELD = 'read_item_types_table_user_field',
  READ_ITEM_TYPES_TABLE_CREATED_AT_FIELD = 'read_item_types_table_created_at_field',
  READ_ITEM_TYPES_TABLE_ITEM_CATEGORY_FIELD = 'read_item_types_table_item_category_field',
  READ_ITEM_TYPES_TABLE_COMPANY_FIELD = 'read_item_types_table_company_field',
  READ_ITEM_TYPES_TABLE_PRICE_FIELD = 'read_item_types_table_price_field',
  READ_ITEM_TYPES_TABLE_ITEM_TAG_FIELD = 'read_item_types_table_item_tag_field',
  READ_ITEM_TYPES_TABLE_DROPDOWN_FIELD = 'read_item_types_table_dropdown_field',

  READ_DEFAULT_ITEM_TYPES_DELETE_ITEM_TYPE_BUTTON = 'read_default_item_types_delete_item_type_button',
  READ_DEFAULT_ITEM_TYPES_UPDATE_ITEM_TYPE_BUTTON = 'read_default_item_types_update_item_type_button',
  READ_ITEM_TYPES_DELETE_ITEM_TYPE_BUTTON = 'read_item_types_delete_item_type_button',
  READ_ITEM_TYPES_UPDATE_ITEM_TYPE_BUTTON = 'read_item_types_update_item_type_button',
  READ_ITEM_TYPES_CREATE_ITEM_TYPE_BUTTON = 'read_item_types_create_item_type_button'
}

export const INITIAL_ITEM_TYPES_FILTERS = {};
export const INITIAL_ITEM_TYPES_PAGE = 1;
export const INITIAL_ITEM_TYPES_SORT = [
  FetchItemTypesSortTypes.CREATED_AT_DESC
];
export const INITIAL_ITEM_TYPES_LIMIT = 50;
