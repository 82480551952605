import { useCallback, useRef } from 'react';
import cl from 'classnames';

interface SecondaryMenuWrapperBackdropProps {
  isSecondaryMenuOpen: boolean;
  closeSecondaryMenu: () => void;
}

function SecondaryMenuWrapperBackdrop({
  isSecondaryMenuOpen,
  closeSecondaryMenu
}: SecondaryMenuWrapperBackdropProps) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      closeSecondaryMenu();
      timeoutRef.current = null;
    }, 350);
  }, [closeSecondaryMenu, timeoutRef]);

  const handleMouseLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [timeoutRef]);

  return (
    <div
      id="menu-backdrop"
      className={cl(
        'absolute inset-0 z-0 bg-gray-200 bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-50 transition-opacity',
        isSecondaryMenuOpen
          ? 'pointer-events-auto opacity-100'
          : 'pointer-events-none opacity-0'
      )}
      onClick={closeSecondaryMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}

export default SecondaryMenuWrapperBackdrop;
