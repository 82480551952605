import {
  Checked,
  CheckedAll,
  CheckAllDisabled,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  BooleanFilter,
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  Currencies,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  FileUrl,
  ID,
  IdFilterType,
  MoneyType,
  NanoID,
  NanoIDFilter,
  NanoIdFilterType,
  StringFilter,
  TextFilterType,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdateItemIsLoading,
  UUID
} from '../../types';

export type ItemTypeID = ID;
export type ItemTypeUUID = UUID;
export type ItemTypeNanoID = NanoID;
export type ItemTypeCreatedAt = CreatedAt;
export type ItemTypeUpdatedAt = UpdatedAt;
export type ItemTypeName = string;
export type ItemTypeDescription = string;
export type ItemTypeImageUrl = FileUrl;
export type ItemTypeNewClientDefaultAt = CreatedAt | null;
export type ItemTypePrice = MoneyType;
// export type ItemTypePriceCurrency = Currencies;
export type ItemTypeViewPrice = MoneyType;
export type ItemTypeViewPriceCurrency = Currencies;

export const enum FetchItemTypesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export interface FetchItemTypesFilters {
  companyNanoId?: NanoIDFilter;
  name?: StringFilter;
  withoutCompany?: BooleanFilter;
  withCompany?: BooleanFilter;
}

export interface FetchFinItemTypesFilters {
  companyNanoId?: NanoIdFilterType;
  name?: TextFilterType;
  id?: IdFilterType;
  nanoId?: NanoIdFilterType;
  withoutCompany?: BooleanFilter;
  withCompany?: BooleanFilter;
}

export type FetchItemTypesGqlQuery = FetchItemsGqlQuery;

export type FetchItemTypesCacheKey = FetchItemsCacheKey;
export type FetchItemTypesCacheKeys = FetchItemTypesCacheKey[];

export type FetchItemTypesSort = FetchItemsSort;
export type FetchItemTypesSortItemTypes = FetchItemsSortItems;
export type FetchItemTypesPage = FetchItemsPage;
export type FetchItemTypesLimit = FetchItemsLimit;
export type FetchItemTypesErrorMessage = FetchItemsErrorMessage;
export type FetchItemTypesFetched = FetchItemsFetched;
export type FetchItemTypesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchItemTypesPaginateItemTypes = FetchItemsPaginateItems;
export type FetchItemTypesPrefetchItemTypes = FetchItemsPrefetchItems;
export type FetchItemTypesTotalCount = FetchItemsTotalCount;

export type FetchItemTypesCheckedAll = CheckedAll;
export type FetchItemTypesCheckAllDisabled = CheckAllDisabled;
export type FetchItemTypesOnCheckAll = OnCheckAll;
export type FetchItemTypesChecked = Checked;
export type FetchItemTypesCheckedHash = CheckedHashItem;
export type FetchItemTypesOnSetCheckedIds = OnSetCheckedIds;

export type FetchItemTypesShowItemType = FetchItemsShowItem;
export type FetchItemTypesEditItemType = FetchItemsEditItem;

export const enum FetchItemTypesSortableFields {
  NAME = 'name',
  ITEM_CATEGORY = 'itemCategory',
  COMPANY = 'company',
  PRICE = 'price',
  USER = 'user',
  CREATED_AT = 'createdAt'
}

export const enum ItemTypeFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  IMAGE_URL = 'imageUrl',
  NEW_CLIENT_DEFAULT_AT = 'newClientDefaultAt',
  ITEM_CATEGORY_ID = 'itemCategoryId',
  ITEM_TAG_IDS = 'itemTagIds',
  ITEM_TAG_NAME = 'itemTagName',
  PRICE = 'price',
  VIEW_PRICE = 'viewPrice',
  VIEW_PRICE_CURRENCY = 'viewPriceCurrency'
}

export type CreateItemTypeIsLoading = CreateItemIsLoading;
export type CreateItemTypeCacheKeys = CreateItemCacheKeys;

export type UpdateItemTypeIsLoading = UpdateItemIsLoading;
export type UpdateItemTypeCacheKeys = UpdateItemCacheKeys;
export type UpdateItemTypeGqlQuery = UpdateItemGqlQuery;

export type DeleteItemTypeCacheKeys = DeleteItemCacheKeys;

export type BatchDestroyItemTypesCacheKeys = UpdateItemCacheKeys;
export type BatchDuplicateItemTypesCacheKeys = UpdateItemCacheKeys;
