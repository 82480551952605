import {
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  StringFilter,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemIsLoading
} from '../../types';
import { UserID } from '../users/usersTypes';

export type ReportSectionID = ID;
export type ReportSectionCreatedAt = CreatedAt;
export type ReportSectionUpdatedAt = UpdatedAt;
export type ReportSectionName = string;
export type ReportSectionUserID = UserID;

export const enum FetchReportSectionsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export interface FetchReportSectionsFilters {
  name?: StringFilter;
}

export type FetchReportSectionsFilterReportSections =
  FetchItemsFilterItems<FetchReportSectionsFilters>;

export type FetchReportSectionsCacheKey = FetchItemsCacheKey;

export type FetchReportSectionsSort = FetchItemsSort;
export type FetchReportSectionsSortReportSections = FetchItemsSortItems;
export type FetchReportSectionsPage = FetchItemsPage;
export type FetchReportSectionsLimit = FetchItemsLimit;
export type FetchReportSectionsErrorMessage = FetchItemsErrorMessage;
export type FetchReportSectionsFetched = FetchItemsFetched;
export type FetchReportSectionsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchReportSectionsPaginateReportSections = FetchItemsPaginateItems;
export type FetchReportSectionsPrefetchReportSections = FetchItemsPrefetchItems;
export type FetchReportSectionsTotalCount = FetchItemsTotalCount;

export type FetchFinReportSectionsPage = FetchItemsPage;
export type FetchFinReportSectionsLimit = FetchItemsLimit;

export type FetchReportSectionsEditReportSection = FetchItemsEditItem;

export const enum FetchReportSectionsSortableFields {
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export type CreateReportSectionIsLoading = CreateItemIsLoading;
export type CreateReportSectionCacheKeys = CreateItemCacheKeys;

export type UpdateReportSectionIsLoading = UpdateItemIsLoading;
export type UpdateReportSectionCacheKeys = UpdateItemCacheKeys;

export type DeleteReportSectionCacheKeys = DeleteItemCacheKeys;
