import toNumber from 'lodash/toNumber';

import {
  CreateGeneralLedgerCacheKeys,
  GeneralLedgerDefaultShareTypes
} from '../../../../../generalLedgersTypes';
import {
  CreateGeneralLedgerFormData,
  CreateGeneralLedgerFormFields
} from '../../CreateGeneralLedgerForm.types';
import { CompanyNanoID } from '../../../../../../companies/companiesTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateGeneralLedger } from '../../../../../hooks/useCreateGeneralLedger';

import { createGeneralLedgerFormSchema } from './useCreateGeneralLedgerForm.schema';

const defaultGeneralLedgerValues: CreateGeneralLedgerFormData = {
  name: '',
  defaultShare: '',
  invoiced: false
};

interface CreateGeneralLedgerFormOptions {
  cacheKeys?: CreateGeneralLedgerCacheKeys;
  companyNanoId?: CompanyNanoID;
}

function useCreateGeneralLedgerForm({
  cacheKeys = [],
  companyNanoId
}: CreateGeneralLedgerFormOptions) {
  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<CreateGeneralLedgerFormData>({
      defaultValues: defaultGeneralLedgerValues,
      schema: createGeneralLedgerFormSchema,
      isModalForm: true
    });

  const {
    createGeneralLedgerReset,
    createGeneralLedgerLoading,
    createGeneralLedgerErrorMessage,
    createGeneralLedger
  } = useCreateGeneralLedger({
    cacheKeys: cacheKeys
  });

  return {
    control,
    resetCreateGeneralLedgerForm: resetForm,
    createGeneralLedgerReset,
    createGeneralLedgerErrorMessage,
    createGeneralLedgerLoading,
    handleCreateGeneralLedger: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        createGeneralLedger({
          generalLedger: {
            name: data.name,
            defaultShare: toNumber(data.defaultShare),
            defaultShareType: data.invoiced
              ? GeneralLedgerDefaultShareTypes.WITH_INVOICE
              : GeneralLedgerDefaultShareTypes.WITHOUT_INVOICE,
            companyNanoId
          }
        })
    }),
    registerFields: {
      registerName: register(CreateGeneralLedgerFormFields.NAME),
      registerInvoiced: register(CreateGeneralLedgerFormFields.INVOICED),
      registerDefaultShare: register(
        CreateGeneralLedgerFormFields.DEFAULT_SHARE
      )
    },
    validationErrors: {
      nameValidationError:
        errors?.[CreateGeneralLedgerFormFields.NAME]?.message,
      defaultShareValidationError:
        errors?.[CreateGeneralLedgerFormFields.DEFAULT_SHARE]?.message
    }
  };
}

export default useCreateGeneralLedgerForm;
