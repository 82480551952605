import { gql } from 'graphql-request';

export const UPDATE_TEAM_COMPLEXITIES_QUERY = gql`
  mutation UpdateTeamComplexities($uuid: ID!, $complexityIds: [ID!]!) {
    updateTeamComplexities(
      input: { uuid: $uuid, complexityIds: $complexityIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
