import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  TextFilterType,
  UUIDFilter
} from '../../../types';

import { EmailTemplateUUID } from './emailTemplatesFieldsTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

export interface FetchEmailTemplatesFilters {
  name?: TextFilterType;
  createdAt?: DateFilterType;
  body?: TextFilterType;
  bodyEn?: TextFilterType;
  bodyUk?: TextFilterType;
  description?: TextFilterType;
  id?: IdFilterType;
  key?: TextFilterType;
  subject?: TextFilterType;
  subjectEn?: TextFilterType;
  subjectUk?: TextFilterType;
  updatedAt?: DateFilterType;
  uuid?: UUIDFilter;
}

export enum FetchEmailTemplatesSortableFields {
  ID = 'id',
  NAME = 'name',
  NAME_EN = 'nameEn',
  CREATED_AT = 'createdAt'
}

export enum FetchEmailTemplatesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchEmailTemplatesGqlQuery = FetchItemsGqlQuery;

export type FetchEmailTemplatesCacheKey = FetchItemsCacheKey;

export type FetchEmailTemplatesFetched = FetchItemsFetched;
export type FetchEmailTemplatesErrorMessage = FetchItemsErrorMessage;
export type FetchEmailTemplatesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchEmailTemplatesPage = FetchItemsPage;
export type FetchEmailTemplatesLimit = FetchItemsLimit;
export type FetchEmailTemplatesSort = FetchItemsSort;
export type FetchEmailTemplatesTotalCount = FetchItemsTotalCount;
export type FetchEmailTemplatesFilterEmailTemplates =
  FetchItemsFilterItems<FetchEmailTemplatesFilters>;
export type FetchEmailTemplatesClearEmailTemplatesFilters =
  FetchItemsClearItemsFilters;
export type FetchEmailTemplatesSortEmailTemplates = FetchItemsSortItems;
export type FetchEmailTemplatesPaginateEmailTemplates = FetchItemsPaginateItems;
export type FetchEmailTemplatesPrefetchEmailTemplates = FetchItemsPrefetchItems;

export type FetchEmailTemplatesShowEmailTemplate =
  FetchItemsShowItem<EmailTemplateUUID>;
export type FetchEmailTemplatesEditEmailTemplate =
  FetchItemsEditItem<EmailTemplateUUID>;

export type FetchEmailTemplatesChecked = Checked;
export type FetchEmailTemplatesCheckedHash = CheckedHashItem;
export type FetchEmailTemplatesOnSetCheckedIds = OnSetCheckedIds;
export type FetchEmailTemplatesCheckedAll = CheckedAll;
export type FetchEmailTemplatesOnCheckAll = OnCheckAll;
