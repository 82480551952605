import { useCallback } from 'react';
import { ID } from '../../../../types';
import {
  UserUUID,
  UserGqlError,
  UpdateUserGqlStatus,
  UpdateUserGqlQuery,
  UpdateUserCacheAction,
  UpdateUserCacheKeys
} from '../../usersTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateUserInput {
  uuid: UserUUID;
  imageId: ID;
}

interface UpdateUserResponse<UpdateUserRecordType> {
  updateUser: {
    status: UpdateUserGqlStatus;
    recordUuid: UserUUID;
    record: UpdateUserRecordType;
    errors: UpdateUserErrors;
  };
}

interface UpdateUserErrors {
  name: UserGqlError;
  fullMessages: UserGqlError;
}

interface UpdateUserOptions {
  query: UpdateUserGqlQuery;
  action: UpdateUserCacheAction;
  cacheKeys?: UpdateUserCacheKeys;
  uuid?: UserUUID;
}

function useUpdateUserImage<UpdateUserRecordType>({
  query,
  action,
  cacheKeys,
  uuid
}: UpdateUserOptions) {
  const { updateQuery, updateQueryErrorMessage } = useUpdateQuery<
    UpdateUserInput,
    UpdateUserResponse<UpdateUserRecordType>,
    UpdateUserErrors,
    UpdateUserRecordType
  >({ action, query, cacheKeys });

  const handleUpdateUserImage = useCallback<(imageId: ID) => Promise<unknown>>(
    (imageId) => updateQuery({ uuid: uuid, imageId: imageId }),
    [updateQuery, uuid]
  );

  return {
    updateUserImageErrorMessage: updateQueryErrorMessage,
    updateUserImage: handleUpdateUserImage
  };
}

export default useUpdateUserImage;
