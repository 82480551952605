import React, { Fragment, useCallback, useEffect, useState } from 'react';
import fromPairs from 'lodash/fromPairs';
import omit from 'lodash/omit';

import { DropzoneUploadedFiles } from '../../../../dropzone/DropzoneUploadedFiles';
import {
  S3MultipartDropzoneArea,
  S3MultipartDropzoneAreaOnFileFailed,
  S3MultipartDropzoneAreaOnFilesAccepted,
  S3MultipartDropzoneAreaOnFileUploaded,
  S3MultipartDropzoneAreaOnUploadProgress
} from '../../../../dropzone/S3MultipartDropzoneArea';
import {
  DropzoneImageHelperFile,
  DropzoneImageHelperFileId,
  DropzoneImageHelperFiles,
  DropzoneImageHelperRequiredProps
} from './DropzoneImageHelper.types';

interface DropzoneImageHelperProps {
  initialFile?: DropzoneImageHelperFiles;
  initialFileId?: DropzoneImageHelperFileId;
  value: DropzoneImageHelperFileId;
  disabled?: boolean;
  maxFiles?: number;
  onChange: (value: DropzoneImageHelperFileId) => void;
  onChangeFile?: (files: DropzoneImageHelperFiles) => void;
}

const initialState = {};

function DropzoneImageHelper({
  type,
  initialFile = initialState,
  initialFileId,
  disabled,
  maxFiles,
  onChange,
  onChangeFile
}: DropzoneImageHelperProps & DropzoneImageHelperRequiredProps) {
  const activeTab = 'general';
  const [image, setImage] = useState<DropzoneImageHelperFiles>(initialFile);
  const [fileId, setFileId] =
    useState<DropzoneImageHelperFileId>(initialFileId);

  const handleFilesDropped =
    useCallback<S3MultipartDropzoneAreaOnFilesAccepted>(
      (files) => {
        setImage({
          ...fromPairs<DropzoneImageHelperFile>(
            files.map((file) => [
              file.id,
              {
                ...file,
                state: 'processing' as const,
                activeTab,
                progresses: {}
              }
            ])
          )
        });
      },
      [activeTab]
    );

  const handleUploadProgress =
    useCallback<S3MultipartDropzoneAreaOnUploadProgress>(
      (id, progress) => {
        setImage((prevState) => ({
          [id]: {
            ...prevState[id],
            progress,
            activeTab
          }
        }));
      },
      [activeTab]
    );

  const handleFileUploaded = useCallback<S3MultipartDropzoneAreaOnFileUploaded>(
    (id, uploadedId) => {
      setImage((prevState) => ({
        [id]: {
          ...prevState[id],
          progress: 100,
          state: 'finished' as const,
          uploadedId,
          activeTab
        }
      }));
      setFileId(uploadedId);
    },
    [activeTab]
  );

  const handleFileFailed = useCallback<S3MultipartDropzoneAreaOnFileFailed>(
    (id) => {
      setImage((prevState) => ({
        [id]: {
          ...prevState[id],
          state: 'failed' as const,
          activeTab
        }
      }));
    },
    [activeTab]
  );

  const handleRemoveItem = useCallback<(id: string) => void>(
    (id: string) => {
      setImage((prevState) => omit<DropzoneImageHelperFiles>(prevState, id));
      setFileId(undefined);
    },
    [setImage]
  );

  useEffect(() => {
    onChange(fileId);
  }, [fileId, onChange]);

  useEffect(() => {
    onChangeFile?.(image);
  }, [image, onChangeFile]);

  return (
    <Fragment>
      <div className="mt-2">
        <S3MultipartDropzoneArea
          type={type}
          disabled={disabled}
          onFilesAccepted={handleFilesDropped}
          onUploadProgress={handleUploadProgress}
          onFileUploaded={handleFileUploaded}
          onFileFailed={handleFileFailed}
          maxFiles={maxFiles}
        />
        <DropzoneUploadedFiles
          activeTab={activeTab}
          allFiles={image}
          onRemoveFile={handleRemoveItem}
        />
      </div>
    </Fragment>
  );
}

export default DropzoneImageHelper;
