import {
  TeamAdditionalDiscount,
  TeamAddress,
  TeamCity,
  TeamCompanyCrm,
  TeamCountryId,
  TeamCurrency,
  TeamDescription,
  TeamDiscount,
  TeamFacebook,
  TeamGqlError,
  TeamImageId,
  TeamInstagram,
  TeamLeadCreationDate,
  TeamLinkedIn,
  TeamManualTeamStatus,
  TeamName,
  TeamNda,
  TeamOwnerID,
  TeamSite,
  TeamSocialNetworks,
  TeamStateID,
  TeamStateName,
  TeamTagsTagID,
  TeamTwitter,
  TeamUUID,
  TeamValidateOutput,
  TeamZipCode,
  TeamTerms,
  UpdateTeamCacheAction,
  UpdateTeamCacheKeys,
  UpdateTeamGqlQuery,
  UpdateTeamGqlStatus
} from '../../teamsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTeamInput {
  uuid: TeamUUID;
  name?: TeamName;
  imageId?: TeamImageId;
  countryId?: TeamCountryId;
  site?: TeamSite;
  address?: TeamAddress;
  facebook?: TeamFacebook;
  linkedIn?: TeamLinkedIn;
  twitter?: TeamTwitter;
  instagram?: TeamInstagram;
  socialNetworks?: TeamSocialNetworks;
  stateId?: TeamStateID;
  stateName?: TeamStateName;
  city?: TeamCity;
  nda?: TeamNda;
  description?: TeamDescription;
  discount?: TeamDiscount;
  additionalDiscount?: TeamAdditionalDiscount;
  tagIds?: TeamTagsTagID[];
  currency?: TeamCurrency;
  validateOutput?: TeamValidateOutput;
  companyCrm?: TeamCompanyCrm;
  manualTeamStatus?: TeamManualTeamStatus;
  leadCreationDate?: TeamLeadCreationDate;
  zipCode?: TeamZipCode;
  ownerId?: TeamOwnerID;
  terms?: TeamTerms;
}

interface UpdateTeamResponse<UpdateTeamRecordType> {
  updateTeam: {
    status: UpdateTeamGqlStatus;
    recordUuid: TeamUUID;
    record: UpdateTeamRecordType;
    errors: UpdateTeamErrors;
  };
}

interface UpdateTeamErrors {
  name: TeamGqlError;
  fullMessages: TeamGqlError;
}

interface UpdateTeamOptions {
  action: UpdateTeamCacheAction;
  query: UpdateTeamGqlQuery;
  cacheKeys?: UpdateTeamCacheKeys;
}

function useUpdateTeam<UpdateTeamRecordType>({
  action,
  query,
  cacheKeys
}: UpdateTeamOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTeamInput,
    UpdateTeamResponse<UpdateTeamRecordType>,
    UpdateTeamErrors,
    UpdateTeamRecordType
  >({ action, query, cacheKeys });

  return {
    updateTeamData: updateQueryData,
    updateTeamError: updateQueryError,
    updateTeamLoading: updateQueryLoading,
    updateTeamErrorMessage: updateQueryErrorMessage,
    updateTeam: updateQuery,
    updateTeamReset: updateQueryReset
  };
}

export default useUpdateTeam;
