export enum StockPermissions {
  READ_THREE_D_STOCK_INDEX_PAGE = 'read_three_d_stock_index_page',
  READ_STOCK_MENU_LINK = 'read_stock_menu_link',
  READ_STOCK_MENU_CREATE_LIBRARY_LINK = 'read_stock_menu_create_library_link',
  READ_STOCK_MENU_LIBRARY_SECTION = 'read_stock_menu_library_section',
  READ_STOCK_MENU_PRODUCTS_LIBRARY_LINK = 'read_stock_menu_products_library_link',
  READ_STOCK_MENU_LIFESTYLES_LIBRARY_LINK = 'read_stock_menu_lifestyles_library_link',
  READ_STOCK_MENU_MATERIALS_LIBRARY_LINK = 'read_stock_menu_materials_library_link',
  READ_STOCK_MENU_MATERIALS_NAV = 'read_stock_menu_materials_nav',
  READ_STOCK_PREMIUM_LIFESTYLES_LINK = 'read_stock_premium_lifestyles_link',
  READ_THREE_D_STOCK_PRODUCTS_INDEX_PAGE = 'read_three_d_stock_products_index_page',
  READ_THREE_D_STOCK_LIFESTYLES_INDEX_PAGE = 'read_three_d_stock_lifestyles_index_page',
  READ_THREE_D_STOCK_MATERIALS_INDEX_PAGE = 'read_three_d_stock_materials_index_page'
}
