import {
  GeneralLedgerName,
  CreateGeneralLedgerCacheKeys,
  GeneralLedgerDefaultShareType,
  GeneralLedgerDefaultShare
} from '../../generalLedgersTypes';
import { BffApiCreateItemError } from '../../../../types';
import { CompanyNanoID } from '../../../companies/companiesTypes';

import { useBffCreateQuery } from '../../../common/hooks/base/reactQuery/useBffCreateQuery';

import { GeneralLedgerBffUrl } from '../../GeneralLedgerBffUrl';

interface CreateGeneralLedgerOptions {
  cacheKeys?: CreateGeneralLedgerCacheKeys;
}

export interface CreateGeneralLedgerInput {
  generalLedger: {
    name: GeneralLedgerName;
    defaultShare?: GeneralLedgerDefaultShare;
    defaultShareType?: GeneralLedgerDefaultShareType;
    companyNanoId?: CompanyNanoID;
  };
}

export type CreateGeneralLedgerError = BffApiCreateItemError;

function useCreateGeneralLedger({ cacheKeys }: CreateGeneralLedgerOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useBffCreateQuery<
    CreateGeneralLedgerInput,
    unknown,
    CreateGeneralLedgerError,
    unknown
  >({ url: GeneralLedgerBffUrl.create(), cacheKeys });

  return {
    createGeneralLedgerData: createQueryData,
    createGeneralLedgerError: createQueryError,
    createGeneralLedgerLoading: createQueryLoading,
    createGeneralLedgerErrorMessage: createQueryErrorMessage,
    createGeneralLedger: createQuery,
    createGeneralLedgerReset: createQueryReset
  };
}

export default useCreateGeneralLedger;
