import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  ModelBrand,
  FetchItemGqlQuery,
  FetchItemCacheKey,
  NanoID
} from '../../../types';

import { UserAvatarLinkUser } from '../../common/helpers/UserAvatarLink';
import { ImageFile } from '../../images/imagesTypes';

export type PageID = ModelBrand<ID, 'PageID'>;
export type PageUUID = ModelBrand<UUID, 'PageUUID'>;
export type PageNanoID = ModelBrand<NanoID, 'PageNanoID'>;
export type PageName = string;
export type PageBody = string;
export type PageNameEn = string;
export type PageBodyEn = string;
export type PageNameUk = string;
export type PageBodyUk = string;
export type PageLocalizedName = string;
export type PageLocalizedBody = string;

export type PageCreatedAt = CreatedAt;
export type PageUpdatedAt = UpdatedAt;

export type PageUserFullName = string;
export type PageUserNanoID = ModelBrand<NanoID, 'PageUserNanoID'>;
export type PageUserFile = ImageFile;
export type PageUserAvatar = UserAvatarLinkUser;

export type FetchPageGqlQuery = FetchItemGqlQuery;
export type FetchPageCacheKey = FetchItemCacheKey;

export const enum PagesFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  BODY = 'body',
  NAME_EN = 'nameEn',
  BODY_EN = 'bodyEn',
  NAME_UK = 'nameUk',
  BODY_UK = 'bodyUk',
  CREATED_AT = 'createdAt'
}
