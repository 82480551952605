import { ID, UUID, CreatedAt, UpdatedAt } from '../../../types';

export type RoleID = ID;
export type RoleUUID = UUID;
export type RoleName = string;
export type RoleDescription = string;
export type RoleCreatedAt = CreatedAt;
export type RoleUpdatedAt = UpdatedAt;

export enum RoleFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  DESCRIPTION = 'description',
  CREATED_AT = 'createdAt'
}
