import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  FileUrl,
  DateType,
  DateFilterType
} from '../../../types';
import { UserID } from '../../users/usersTypes';
import { TagID } from '../../tags/tagsTypes';

export type ReportID = ID;
export type ReportUUID = UUID;
export type ReportReportType = ReportTypeOptions;
export type ReportFile = FileUrl;
export type ReportFilters = { [key: string]: any };
export type ReportProgress = number;
export type ReportStatus = string;
export type ReportErrorMessage = string;
export type ReportBacktrace = string;
export type ReportFinishedAt = DateType;
export type ReportCreatedAt = CreatedAt;
export type ReportUpdatedAt = UpdatedAt;
export type ReportTotalRecordsCount = number;
export type ReportTotalXlsReportRowsCount = number;
export type ReportInitializedXlsReportRowsCount = number;
export type ReportProcessingXlsReportRowsCount = number;
export type ReportFailedXlsReportRowsCount = number;
export type ReportCompletedXlsReportRowsCount = number;
export type ReportUserID = UserID;
export type ReportTagIDs = TagID[];

export type ReportCreatedAtFilter = DateFilterType;

export const enum ReportFormTypes {
  DATE_RANGE_FORM = 'date_range_form',
  DATE_RANGE_TAGS_FORM = 'date_range_tags_form',
  USER_FORM = 'user_form'
}

export const enum ReportTypeOptions {
  PROJECTS_GLOBAL = 'tasks_global',
  PROJECTS_GLOBAL_LITE1 = 'projects_global_lite1',
  PROJECTS_GLOBAL_LITE2 = 'projects_global_lite2',
  PROJECTS_GLOBAL_LITE3 = 'projects_global_lite3',
  TASKS_INDEX = 'tasks_index',
  TASKS_GLOBAL = 'discussions_global',
  TASKS_GLOBAL_LITE = 'discussions_global_lite',
  TASKS_GLOBAL_LITE1 = 'discussions_global_lite1',
  TASKS_GLOBAL_LITE2 = 'discussions_global_lite2',
  TASKS_GLOBAL_LITE3 = 'discussions_global_lite3',
  CLIENTS_GLOBAL = 'clients_global',
  CLIENTS_GLOBAL_LITE = 'clients_global_lite',
  CLIENTS_GLOBAL_LITE1 = 'clients_global_lite1',
  CLIENTS_PULSE = 'clients_pulse',
  WORKERS_PULSE = 'workers_pulse',
  WORKERS_GLOBAL = 'workers_global',
  RANGE_REVENUE_RECOGNITION = 'range_revenue_recognition',
  TOTAL_CLIENT_PAYMENTS = 'total_amounts',
  CLIENTS_REVENUE = 'clients_revenue',
  WORKERS_REVENUE = 'workers_revenue',
  CLIENT_TO_WORKER_PAYMENTS = 'workers_clients',
  RECEIVED_PAYMENTS = 'payer_bills',
  WORKERS_TIME = 'workers_time',
  REVENUE_SHARE = 'users_revenue_share',
  CLIENTS_AVERAGE_PAYMENT_FREQUENCY = 'clients_average_payment_frequency',
  WORKERS_DOWNLOADS = 'workers_downloads',
  WORKERS_DOWNLOADS_DETAILED = 'workers_downloads_detailed',

  GLOBAL_PROJECTS_BALANCE = 'projects_global_balance',
  GLOBAL_TASKS_BALANCE = 'tasks_global_balance',
  CLIENTS_BALANCES = 'clients_balances',
  WORKERS_BALANCES = 'workers_balances',

  SUSPENDED_TASKS = 'suspended_discussions',
  GLOBAL_PROJECTS_DEBT = 'projects_global_debt',
  GLOBAL_TASKS_DEBT = 'global_tasks_debt',
  GLOBAL_CLIENTS_DEBT = 'clients_global_debt',
  GLOBAL_WORKERS_DEBT = 'workers_global_debt',
  GLOBAL_TEAMS_AND_CLIENTS = 'global_teams_clients',
  TASKS_ACCOUNT_RECEIVABLE = 'account_receivable_discussions',
  RFA = 'acceptance',
  LIBRARY_USAGE = 'library_usage',
  CLIENTS_FILES_USAGE = 'clients_files_usage',
  CLIENTS_REQUIREMENTS_FILES = 'clients_requirements_files',
  BLOCKED_USERS_ALLOCATIONS = 'blocked_users_allocations',

  BURNED_TASKS = 'burned_discussions',
  TASKS_CHECK_LISTS = 'discussions_check_lists',
  REPLY_RATE = 'discussions_delta',
  POSTPONED_TERMS = 'postponed_terms',
  TAGGED_TASKS = 'tags_discussions',
  ACTQ = 'actq',

  MANAGERS_DYNAMICS = 'managers_dynamics',

  DOUBLE_DISTRIBUTIONS = 'double_distributions',
  MINUS_DISTRIBUTIONS = 'minus_distributions'
}

export const enum ReportFields {
  ID = 'id',
  UUID = 'uuid',
  CREATED_AT = 'createdAt',
  USER_ID = 'userId',
  TAG_IDS = 'tagIds'
}

export const enum ReportsSortEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  UUID_ASC = 'UUID_ASC',
  UUID_DESC = 'UUID_DESC'
}
