import { useMemo } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  FetchLifestylesFilters,
  LifestyleClientID
} from '../../../lifestyles/lifestylesTypes';
import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../components/menus/ThreeDStockIndexPageLifestylesCategoriesNav/ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { FetchLifestyleCategoriesQueryResponse } from '../../../lifestyles/queries/fetchLifestyleCategories.query';

import { useFinLifestyleCategories } from '../../../lifestyles/hooks/useFinLifestyleCategories';
import { useLifestyleCategories } from '../../../lifestyles/hooks/useLifestyleCategories';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { LifestyleCache } from '../../../lifestyles/LifestyleCache';

interface ThreeDStockLifestylesCategoriesOptions {
  clientIds?: LifestyleClientID[];
  lifestylesFilters?: FetchLifestylesFilters;
}

function useThreeDStockLifestylesCategories({
  lifestylesFilters
}: ThreeDStockLifestylesCategoriesOptions) {
  const {
    lifestyleCategories,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData
  } = useFinLifestyleCategories({
    cacheKey: LifestyleCache.notEmptyCategoriesCacheKey(),
    roomsByLifestyle: omit(lifestylesFilters, [
      'lifestyleCategoryId',
      'lifestyleParentCategoryIds'
    ])
  });

  const {
    lifestyleCategories: allLifestyleCategories,
    lifestyleCategoriesErrorMessage: allLifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched: allLifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData:
      allLifestyleCategoriesIsPlaceholderData
  } = useLifestyleCategories({
    cacheKey: LifestyleCache.categoriesCacheKey()
  });

  const lifestyleCategoriesWithParents = useMemo<
    FetchLifestyleCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      lifestyleCategories?.map((category) => category.parent)
    );

    const parentWithParentCategories = compact(
      parentCategories?.map(
        (category) =>
          find(allLifestyleCategories, { id: category.id }) || category
      )
    );

    return sortBy(
      uniqBy([...lifestyleCategories, ...parentWithParentCategories], 'id'),
      'name'
    );
  }, [allLifestyleCategories, lifestyleCategories]);

  useShowToastOnErrorChange({ error: lifestyleCategoriesErrorMessage });

  const parentCategories = useMemo<
    ThreeDStockIndexPageLifestylesCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(lifestyleCategories, (category) => !category.parent),
        'localizedName'
      ),

    [lifestyleCategories]
  );

  return {
    parentCategories,
    lifestyleCategoriesWithParents,
    lifestyleCategoriesErrorMessage:
      lifestyleCategoriesErrorMessage || allLifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched:
      lifestyleCategoriesFetched || allLifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData:
      lifestyleCategoriesIsPlaceholderData &&
      allLifestyleCategoriesIsPlaceholderData
  };
}

export default useThreeDStockLifestylesCategories;
