import { IconsEnum } from '../../../../../assets/icons/types';
import { I18nText, ClassName } from '../../../../../types';
import {
  GeneralLedgerDefaultShare,
  GeneralLedgerDefaultShareType,
  GeneralLedgerID,
  GeneralLedgerName,
  UpdateGeneralLedgerCacheKeys
} from '../../../generalLedgersTypes';

import {
  UpdateGeneralLedgerForm,
  useUpdateGeneralLedgerForm
} from '../../forms/UpdateGeneralLedgerForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { generalLedgersKeys, words } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

const UPDATE_GENERAL_LEDGER_FORM = 'update-general-ledger-form';

interface UpdateGeneralLedgerModalButtonGeneralLedger {
  id: GeneralLedgerID;
  name: GeneralLedgerName;
  defaultShare: GeneralLedgerDefaultShare;
  defaultShareType: GeneralLedgerDefaultShareType;
}

interface UpdateGeneralLedgerModalButtonProps {
  generalLedger: UpdateGeneralLedgerModalButtonGeneralLedger;
  cacheKeys?: UpdateGeneralLedgerCacheKeys;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  disabled?: boolean;
}

function UpdateGeneralLedgerModalButton({
  generalLedger,
  cacheKeys,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  className,
  icon,
  iconClassName,
  disabled
}: UpdateGeneralLedgerModalButtonProps) {
  const {
    registerFields,
    handleUpdateGeneralLedger,
    resetUpdateGeneralLedgerForm,
    updateGeneralLedgerReset,
    updateGeneralLedgerLoading,
    updateGeneralLedgerErrorMessage,
    validationErrors,
    control
  } = useUpdateGeneralLedgerForm({ generalLedger, cacheKeys });

  return (
    <FormModalButton
      className={className}
      form={UPDATE_GENERAL_LEDGER_FORM}
      i18nTitle={generalLedgersKeys.editSubbook}
      i18nText={i18nText}
      i18nSubmitText={words.update}
      icon={icon}
      iconClassName={iconClassName}
      onClose={resetUpdateGeneralLedgerForm}
      isLoading={updateGeneralLedgerLoading}
      onOpen={updateGeneralLedgerReset}
      onSubmit={handleUpdateGeneralLedger}
      disabled={disabled}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
    >
      <UpdateGeneralLedgerForm
        form={UPDATE_GENERAL_LEDGER_FORM}
        isLoading={updateGeneralLedgerLoading}
        registerName={registerFields.registerName}
        nameValidationError={validationErrors.nameValidationError}
        defaultShareValidationError={
          validationErrors.defaultShareValidationError
        }
        control={control}
        registerDefaultShare={registerFields.registerDefaultShare}
      />

      <div className="px-4">
        <AlertMessage message={updateGeneralLedgerErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateGeneralLedgerModalButton;
