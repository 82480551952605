import React, { ReactNode } from 'react';

interface SecondaryMenuWrapperBodyWrapperProps {
  children: ReactNode;
}

function SecondaryMenuWrapperBodyWrapper({
  children
}: SecondaryMenuWrapperBodyWrapperProps) {
  return (
    <div className="flex-1 relative">
      <div className="absolute inset-0 overflow-y-auto overflow-x-hidden pb-10 sm:pb-0">
        <div className="flex flex-col w-full py-3">{children}</div>
      </div>
    </div>
  );
}

export default SecondaryMenuWrapperBodyWrapper;
