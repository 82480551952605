import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  TextFilterType
} from '../../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

export interface FetchSentMessagesFilters {
  email?: TextFilterType;
  subject?: TextFilterType;
  body?: TextFilterType;
  createdAt?: DateFilterType;
}

export enum FetchSentMessagesSortableFields {
  ID = 'id',
  EMAIL = 'email',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}

export enum FetchSentMessagesFilterFields {
  EMAIL = 'email',
  CREATED_AT = 'createdAt'
}

export enum FetchSentMessagesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type FetchSentMessagesGqlQuery = FetchItemsGqlQuery;

export type FetchSentMessagesCacheKey = FetchItemsCacheKey;

export type FetchSentMessagesFetched = FetchItemsFetched;
export type FetchSentMessagesErrorMessage = FetchItemsErrorMessage;
export type FetchSentMessagesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchSentMessagesPage = FetchItemsPage;
export type FetchSentMessagesLimit = FetchItemsLimit;
export type FetchSentMessagesSort = FetchItemsSort; // FetchSentMessagesSortTypes[];
export type FetchSentMessagesTotalCount = FetchItemsTotalCount;
export type FetchSentMessagesFilterSentMessages =
  FetchItemsFilterItems<FetchSentMessagesFilters>;
export type FetchSentMessagesClearSentMessagesFilters =
  FetchItemsClearItemsFilters;
export type FetchSentMessagesSortSentMessages = FetchItemsSortItems;
export type FetchSentMessagesPaginateSentMessages = FetchItemsPaginateItems;
export type FetchSentMessagesPrefetchSentMessages = FetchItemsPrefetchItems;

export type FetchSentMessagesShowSentMessage = FetchItemsShowItem;

export type FetchSentMessagesChecked = Checked;
export type FetchSentMessagesCheckedHash = CheckedHashItem;
export type FetchSentMessagesOnSetCheckedIds = OnSetCheckedIds;
export type FetchSentMessagesCheckedAll = CheckedAll;
export type FetchSentMessagesOnCheckAll = OnCheckAll;
