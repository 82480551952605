// import { GeneralLedgerNanoID } from './generalLedgersTypes';

import { GeneralLedgerID } from './generalLedgersTypes';

const baseUrl = 'general_ledgers';

export class GeneralLedgerBffUrl {
  static create() {
    return baseUrl;
  }

  static update(generalLedgerId: GeneralLedgerID) {
    return `${baseUrl}/${generalLedgerId}`;
  }

  static toggleAutoAllocate(generalLedgerId: GeneralLedgerID) {
    return `${baseUrl}/${generalLedgerId}/toggle_auto_allocate`;
  }

  // static delete(itemTypeNanoID: GeneralLedgerNanoID) {
  //   return `${baseUrl}/${itemTypeNanoID}`;
  // }
}
