import {
  GeneralLedgerDefaultShareTypes,
  GeneralLedgerName
} from '../../../generalLedgersTypes';

export interface UpdateGeneralLedgerFormData {
  name: GeneralLedgerName;
  defaultShare: GeneralLedgerName;
  invoiced: GeneralLedgerDefaultShareTypes;
}

export const enum UpdateGeneralLedgerFormFields {
  NAME = 'name',
  DEFAULT_SHARE = 'defaultShare',
  INVOICED = 'invoiced'
}
