import { FetchJournalRecordImportsSortTypes } from './journalRecordImportsTypes';

export const INITIAL_JOURNAL_RECORD_IMPORTS_FILTERS = {};
export const INITIAL_JOURNAL_RECORD_IMPORTS_PAGE = 1;
export const INITIAL_JOURNAL_RECORD_IMPORTS_SORT = [
  FetchJournalRecordImportsSortTypes.ID_DESC
];
export const INITIAL_JOURNAL_RECORDS_IMPORTS_LIMIT = 50;

export enum JournalRecordImportPagesTabs {
  ROWS = 'Rows',
  INVOICES = 'Invoices'
}

export enum JournalRecordImportsPermissions {
  READ_ADMIN_MENU_JOURNAL_RECORD_IMPORTS_MENU = 'read_admin_menu_journal_record_imports_menu',
  READ_JOURNAL_RECORD_IMPORTS_INDEX_PAGE = 'read_journal_record_imports_index_page',
  READ_JOURNAL_RECORD_IMPORT_INVOICES_PAGE = 'read_journal_record_import_invoices_page'
}
