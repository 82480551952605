import { ID, UUID, CreatedAt, UpdatedAt, IndexedAt } from '../../../types';

export type SentMessageID = ID;
export type SentMessageUUID = UUID;
export type SentMessageEmail = string;
export type SentMessageSubject = string;
export type SentMessageKind = string;
export type SentMessageMailer = string;
export type SentMessageHostName = string;
export type SentMessageBody = string;
export type SentMessageProperties = { [key: string]: string };
export type SentMessageOriginalId = string;
export type SentMessageMessageId = string;
export type SentMessageApiId = string;
export type SentMessageCreatedAt = CreatedAt;
export type SentMessageUpdatedAt = UpdatedAt;
export type SentMessageIndexedAt = IndexedAt;

export enum SentMessageFields {
  ID = 'id',
  UUID = 'uuid',
  EMAIL = 'email',
  SUBJECT = 'subject',
  BODY = 'body',
  CREATED_AT = 'createdAt'
}
