import { I18nText } from '../../../../../types';
import {
  FetchProductsFilters,
  ProductClientID,
  ProductsFiltersNavBasePath
} from '../../../../products/productsTypes';

import { useThreeDStockProductsCategories } from '../../../hooks/useThreeDStockProductsCategories';

import { ThreeDStockIndexPageProductsCategoriesNavBody } from './components/ThreeDStockIndexPageProductsCategoriesNavBody';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface ThreeDStockIndexPageProductsCategoriesNavProps {
  allCustomI18nText?: I18nText;
  clientIds?: ProductClientID[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
  isShowAll?: boolean;
  productsFilters?: FetchProductsFilters;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageProductsCategoriesNav({
  allCustomI18nText,
  clientIds,
  filtersNavBasePath,
  isShowAll,
  productsFilters,
  withNavHead
}: ThreeDStockIndexPageProductsCategoriesNavProps) {
  const {
    parentCategories,
    productCategoriesWithParents,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useThreeDStockProductsCategories({ clientIds, productsFilters });

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <ThreeDStockIndexPageProductsCategoriesNavBody
          allCustomI18nText={allCustomI18nText}
          filtersNavBasePath={filtersNavBasePath}
          isShowAll={isShowAll}
          parentCategories={parentCategories}
          productCategories={productCategoriesWithParents}
          withNavHead={withNavHead}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNav;
