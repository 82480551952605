import { Translate } from '../../../../../../helpers/Translate';

interface SecondaryMenuWrapperHeadProps {
  i18nTitle: string;
}

function SecondaryMenuWrapperHead({
  i18nTitle
}: SecondaryMenuWrapperHeadProps) {
  return (
    <div className="flex-shrink-0 h-16 border-b border-gray-200 dark:border-gray-700 relative">
      <div className="absolute inset-0 overflow-hidden">
        <div className="flex flex-row w-full h-full items-center px-6">
          <h2 className="text-lg font-extrabold truncate mr-auto">
            <Translate id={i18nTitle} />
          </h2>
        </div>
      </div>
    </div>
  );
}

export default SecondaryMenuWrapperHead;
