import includes from 'lodash/includes';

import { StockSecondaryMenuItems } from '../../CompactStockSecondaryMenu.types';

function getStockActiveMenu(pathname: string): StockSecondaryMenuItems | null {
  if (includes(pathname, '/products')) {
    return StockSecondaryMenuItems.PRODUCTS;
  }

  if (includes(pathname, '/lifestyles')) {
    return StockSecondaryMenuItems.LIFESTYLES;
  }

  if (includes(pathname, '/materials')) {
    return StockSecondaryMenuItems.MATERIALS;
  }

  return null;
}

export default getStockActiveMenu;
