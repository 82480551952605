import { FetchEmailTemplatesSortTypes } from './emailTemplatesTypes';

export const INITIAL_EMAIL_TEMPLATES_FILTERS = {};
export const INITIAL_EMAIL_TEMPLATES_PAGE = 1;
export const INITIAL_EMAIL_TEMPLATES_SORT = [
  FetchEmailTemplatesSortTypes.CREATED_AT_DESC
];
export const INITIAL_EMAIL_TEMPLATE_LIMIT = 20;

export enum EmailTemplatesPermissions {
  READ_EMAIL_TEMPLATES_INDEX_PAGE = 'read_email_templates_index_page',
  READ_ADMIN_MENU_EMAIL_TEMPLATES_LINK = 'read_admin_menu_email_templates_link',
  READ_EMAIL_TEMPLATES_SEARCH_FILTERS_POPOVER = 'read_email_templates_search_filters_popover'
}
