import React, { Fragment } from 'react';

import { I18nText } from '../../../../../../../types';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategories } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavHead } from '../ThreeDStockIndexPageProductsCategoriesNavHead';
import { ThreeDStockIndexPageProductsCategoriesNavList } from '../ThreeDStockIndexPageProductsCategoriesNavList';

interface ThreeDStockIndexPageProductsCategoriesNavBodyProps {
  allCustomI18nText?: I18nText;
  filtersNavBasePath: ProductsFiltersNavBasePath;
  isShowAll?: boolean;
  parentCategories: ThreeDStockIndexPageProductsCategoriesNavCategories;
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategories;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageProductsCategoriesNavBody({
  allCustomI18nText,
  filtersNavBasePath,
  isShowAll,
  parentCategories,
  productCategories,
  withNavHead
}: ThreeDStockIndexPageProductsCategoriesNavBodyProps) {
  return (
    <Fragment>
      {withNavHead && <ThreeDStockIndexPageProductsCategoriesNavHead />}

      <ThreeDStockIndexPageProductsCategoriesNavList
        allCustomI18nText={allCustomI18nText}
        filtersNavBasePath={filtersNavBasePath}
        isShowAll={isShowAll}
        parentCategories={parentCategories}
        productCategories={productCategories}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavBody;
