import { GeneralLedgerName } from '../../../generalLedgersTypes';

export interface CreateGeneralLedgerFormData {
  name: GeneralLedgerName;
  defaultShare: GeneralLedgerName;
  invoiced: boolean;
}

export const enum CreateGeneralLedgerFormFields {
  NAME = 'name',
  DEFAULT_SHARE = 'defaultShare',
  INVOICED = 'invoiced'
}
