import React from 'react';

import {
  ErrorMessage,
  FormID,
  RegisterFormFieldType
} from '../../../../../types';
import { CreateGeneralLedgerIsLoading } from '../../../generalLedgersTypes';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';

import { formsFields, generalLedgersKeys } from '../../../../../locales/keys';

interface CreateGeneralLedgerFormProps {
  form: FormID;
  isLoading?: CreateGeneralLedgerIsLoading;
  nameValidationError?: ErrorMessage;
  defaultShareValidationError?: ErrorMessage;
  registerName: RegisterFormFieldType<HTMLInputElement>;
  registerDefaultShare: RegisterFormFieldType<HTMLInputElement>;
  registerInvoiced: RegisterFormFieldType<HTMLInputElement>;
}

function CreateGeneralLedgerForm({
  form,
  isLoading,
  nameValidationError,
  defaultShareValidationError,
  registerName,
  registerInvoiced,
  registerDefaultShare
}: CreateGeneralLedgerFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2 text-sm" id={form}>
      <div className="p-4 space-y-4">
        <div>
          <InputField
            disabled={isLoading}
            error={nameValidationError}
            inputWrapperClassName="mt-1 relative"
            i18nLabel={generalLedgersKeys.subbookName}
            name={registerName.name}
            ref={registerName.ref}
            onChange={registerName.onChange}
            type="text"
          />
        </div>

        <div>
          <InputField
            disabled={isLoading}
            error={defaultShareValidationError}
            inputWrapperClassName="mt-1 relative"
            i18nLabel={formsFields.share}
            name={registerDefaultShare.name}
            ref={registerDefaultShare.ref}
            onChange={registerDefaultShare.onChange}
            type="number"
            step="any"
          />
        </div>

        <div>
          <CheckBoxField
            disabled={isLoading}
            i18nLabel={formsFields.invoiced}
            ref={registerInvoiced.ref}
            onChange={registerInvoiced.onChange}
            name={registerInvoiced.name}
            id="invoiced"
          />
        </div>
      </div>
    </Form>
  );
}

export default CreateGeneralLedgerForm;
