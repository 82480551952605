import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';

import { I18nText } from '../../../../../../../types';
import { PrimaryNavTabTooltipItem } from '../../../../../../../app/components/menus/PrimaryNav/helpers/PrimaryNavTooltipMenuTab/PrimaryNavTooltipMenuTab.types';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import { getStockActiveMenu } from '../../utils/getStockActiveMenu';

import {
  companyLibraryMenuLinks,
  selfCompanyLibraryMenuLinks
} from '../../../CompactCompanyLibraryMenu/compactCompanyLibraryMenuConstants';
import { stockMenuLinks } from '../../compactStockSecondaryMenuConstants';

import { menuKeys } from '../../../../../../../locales/keys';

function useCompactStockSecondaryMenu(companyNanoId?: TeamNanoID) {
  const { pathname } = useRouter();

  const activeMenu = getStockActiveMenu(pathname);

  let menuLinks: PrimaryNavTabTooltipItem[] = stockMenuLinks;
  let i18nMenuTitle: I18nText = menuKeys.tabs.stock3d;

  if (startsWith(pathname, '/company')) {
    menuLinks = selfCompanyLibraryMenuLinks;
    i18nMenuTitle = menuKeys.tabs.myLibrary;
  }

  if (startsWith(pathname, '/companies') && companyNanoId) {
    menuLinks = companyLibraryMenuLinks(companyNanoId);
    i18nMenuTitle = menuKeys.tabs.myLibrary;
  }

  return { activeMenu, i18nMenuTitle, menuLinks };
}

export default useCompactStockSecondaryMenu;
