import React from 'react';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';

import { menuKeys } from '../../../../../locales/keys/models/menu';

function DefaultSecondaryMenuUsers() {
  return (
    <SecondaryMenuWrapper i18nTitle={menuKeys.tabs.notifications}>
      {'users menu'}
    </SecondaryMenuWrapper>
  );
}

export default DefaultSecondaryMenuUsers;
