import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { CreateItemUrl, CreateItemCacheKeys } from '../../../../../../types';

import {
  parseRequestError,
  ErrorType
} from '../../../../../../utils/parseRequestError';

import { BffApiRequest } from '../../../../../../utils/BffApiRequest';

interface BffUpdateQueryOptions {
  url: CreateItemUrl;
  cacheKeys?: CreateItemCacheKeys;
}

export interface BffUpdateQueryContext<ItemType> {
  previousData: ItemType;
}

function useBffUpdateQuery<
  UpdateQueryInput,
  UpdateQueryError extends ErrorType = null,
  UpdateQueryResponse = unknown,
  ItemType = unknown
>({ url, cacheKeys }: BffUpdateQueryOptions) {
  const queryClient = useQueryClient();

  const handleQuery = async (input: UpdateQueryInput) => {
    try {
      const response = await BffApiRequest.patch<UpdateQueryResponse>(
        url,
        input
      );

      return response.data;
    } catch (err) {
      throw isEmpty(err?.response?.data?.error?.fullMessages)
        ? err
        : err?.response?.data?.error;
    }
  };

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateQueryResponse,
    UpdateQueryError,
    UpdateQueryInput,
    BffUpdateQueryContext<ItemType>
  >(handleQuery, {
    onSettled: () => {
      if (isArray(cacheKeys)) {
        cacheKeys.map((eachCacheKey) =>
          queryClient.invalidateQueries(eachCacheKey)
        );
      }
    }
  });

  return {
    updateQueryData: data,
    updateQueryError: error,
    updateQueryLoading: isLoading,
    updateQueryErrorMessage: parseRequestError(error),
    updateQuery: mutateAsync,
    updateQueryReset: reset
  };
}

export default useBffUpdateQuery;
