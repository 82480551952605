import { FetchItemCategoriesSortTypes } from './itemCategoriesTypes';

export enum ItemCategoriesPermissions {
  READ_ADMIN_MENU_ITEM_CATEGORIES_LINK = 'read_admin_menu_item_categories_link',

  READ_ITEM_CATEGORIES_INDEX_PAGE = 'read_item_categories_index_page',

  READ_ITEM_CATEGORIES_TABLE_SELECTED_FIELD = 'read_item_categories_table_selected_field',
  READ_ITEM_CATEGORIES_TABLE_NAME_FIELD = 'read_item_categories_table_name_field',
  READ_ITEM_CATEGORIES_TABLE_USER_FIELD = 'read_item_categories_table_user_field',
  READ_ITEM_CATEGORIES_TABLE_CREATED_AT_FIELD = 'read_item_categories_table_created_at_field',
  READ_ITEM_CATEGORIES_TABLE_DROPDOWN_FIELD = 'read_item_categories_table_dropdown_field',

  READ_ITEM_CATEGORIES_DELETE_ITEM_CATEGORY_BUTTON = 'read_item_categories_delete_item_category_button',
  READ_ITEM_CATEGORIES_UPDATE_ITEM_CATEGORY_BUTTON = 'read_item_categories_update_item_category_button',
  READ_ITEM_CATEGORIES_CREATE_ITEM_CATEGORY_BUTTON = 'read_item_categories_create_item_category_button'
}

export const INITIAL_ITEM_CATEGORIES_FILTERS = {};
export const INITIAL_ITEM_CATEGORIES_PAGE = 1;
export const INITIAL_ITEM_CATEGORIES_SORT = [
  FetchItemCategoriesSortTypes.CREATED_AT_DESC
];
export const INITIAL_ITEM_CATEGORIES_LIMIT = 50;
