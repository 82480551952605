import {
  GeneralLedgerDefaultShare,
  GeneralLedgerDefaultShareType,
  GeneralLedgerID,
  GeneralLedgerName,
  UpdateGeneralLedgersCacheKeys
} from '../../generalLedgersTypes';

import { useBffUpdateQuery } from '../../../common/hooks/base/reactQuery/useBffUpdateQuery';

import { GeneralLedgerBffUrl } from '../../GeneralLedgerBffUrl';

interface UpdateGeneralLedgerOptions {
  generalLedgerId: GeneralLedgerID;
  cacheKeys: UpdateGeneralLedgersCacheKeys;
}

interface UpdateGeneralLedgerInput {
  name?: GeneralLedgerName;
  defaultShare?: GeneralLedgerDefaultShare;
  defaultShareType?: GeneralLedgerDefaultShareType;
}

function useUpdateGeneralLedger({
  generalLedgerId,
  cacheKeys
}: UpdateGeneralLedgerOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useBffUpdateQuery<UpdateGeneralLedgerInput>({
    url: GeneralLedgerBffUrl.update(generalLedgerId),
    cacheKeys
  });

  return {
    updateGeneralLedger: updateQuery,
    updateGeneralLedgerData: updateQueryData,
    updateGeneralLedgerError: updateQueryError,
    updateGeneralLedgerErrorMessage: updateQueryErrorMessage,
    updateGeneralLedgerLoading: updateQueryLoading,
    updateGeneralLedgerReset: updateQueryReset
  };
}

export default useUpdateGeneralLedger;
