// import { words } from '../../locales/keys';
// import { chatGptPromptsKeys } from './../../locales/keys/models/chatGptPrompts';

import { FetchChatGptPromptsSortTypes } from './chatGptPromptsTypes';

export const INITIAL_CHAT_GPT_PROMPTS_FILTERS = {};
export const INITIAL_CHAT_GPT_PROMPTS_PAGE = 1;
export const INITIAL_CHAT_GPT_PROMPTS_SORT = [
  FetchChatGptPromptsSortTypes.CREATED_AT_DESC
];
export const INITIAL_CHAT_GPT_PROMPTS_LIMIT = 50;

export const enum ChatGptPromptsPermissions {
  READ_ADMIN_MENU_CHAT_GPT_PROMPTS_LINK = 'read_admin_menu_chat_gpt_prompts_link',
  READ_CHAT_GPT_PROMPTS_INDEX_PAGE = 'read_chat_gpt_prompts_index_page',
  READ_CREATE_CHAT_GPT_PROMPT_MODAL_BUTTON = 'read_create_chat_gpt_prompt_modal_button',
  READ_UPDATE_CHAT_GPT_PROMPT_BUTTON = 'read_update_chat_gpt_prompt_button'
}

// export const chatGptPromptTypes = [
//   { value: 'index', i18nLabel: chatGptPromptsKeys.scopes.index },
//   { value: 'create', i18nLabel: chatGptPromptsKeys.scopes.create },
//   { value: 'read', i18nLabel: chatGptPromptsKeys.scopes.read },
//   { value: 'update', i18nLabel: chatGptPromptsKeys.scopes.update },
//   { value: 'delete', i18nLabel: chatGptPromptsKeys.scopes.delete },
//   { value: 'download', i18nLabel: chatGptPromptsKeys.scopes.download }
// ];
//
// export const chatGptPromptTypesOptions = [
//   { value: null, i18nLabel: words.anyType },
//   ...chatGptPromptTypes
// ];
