import React from 'react';
import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MenuMajorLink } from '../MenuMajorLink';

import { menuKeys } from '../../../../../locales/keys';

import { AppPermissions } from '../../../../../app/appConstants';

import { MessagePath } from '../../../../messages/MessagePath';
import { TaskPath } from '../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../projects/ProjectPath';

function DefaultSecondaryMenuWorkspace() {
  const { pathname } = useRouter();

  return (
    <SecondaryMenuWrapper i18nTitle={menuKeys.tabs.workspace}>
      <CheckPermissions
        action={AppPermissions.READ_TASKS_AND_PROJECTS_MENU_TASKS_LINK}
      >
        <div className="p-4">
          <MenuMajorLink
            href={TaskPath.index()}
            i18nText={menuKeys.links.tasks}
            active={startsWith(pathname, TaskPath.index())}
          />
        </div>
      </CheckPermissions>
      <CheckPermissions
        action={AppPermissions.READ_TASKS_AND_PROJECTS_MENU_PROJECTS_LINK}
      >
        <div className="p-4">
          <MenuMajorLink
            href={ProjectPath.index()}
            i18nText={menuKeys.links.projects}
            active={startsWith(pathname, ProjectPath.index())}
          />
        </div>
      </CheckPermissions>
      <CheckPermissions
        action={AppPermissions.READ_TASKS_AND_PROJECTS_MENU_MESSAGES_LINK}
      >
        <div className="p-4">
          <MenuMajorLink
            href={MessagePath.index()}
            i18nText={menuKeys.links.messages}
            active={startsWith(pathname, MessagePath.index())}
          />
        </div>
      </CheckPermissions>
    </SecondaryMenuWrapper>
  );
}

export default DefaultSecondaryMenuWorkspace;
