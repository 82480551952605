import React from 'react';
import { Controller, FieldPath, Control } from 'react-hook-form';

import {
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl,
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlRequiredProps,
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlValue
} from './components/UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl';

interface UpdateGeneralLedgerFormInvoicedCheckBoxFieldRequiredProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
}

function UpdateGeneralLedgerFormInvoicedCheckBoxField<T>({
  control,
  disabled,
  className,
  errorClassName,
  id,
  checkBoxClassName,
  label,
  i18nLabel,
  labelClassName,
  name,
  required
}: UpdateGeneralLedgerFormInvoicedCheckBoxFieldRequiredProps<T> &
  UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <UpdateGeneralLedgerFormInvoicedCheckBoxFieldControl
          value={
            value as UpdateGeneralLedgerFormInvoicedCheckBoxFieldControlValue
          }
          error={error?.message}
          disabled={disabled}
          className={className}
          errorClassName={errorClassName}
          id={id}
          checkBoxClassName={checkBoxClassName}
          label={label}
          i18nLabel={i18nLabel}
          labelClassName={labelClassName}
          required={required}
          name={name}
          onChange={onChange}
        />
      )}
    />
  );
}

export default UpdateGeneralLedgerFormInvoicedCheckBoxField;
