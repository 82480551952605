import React, { ReactNode, useState } from 'react';

import { I18nText } from '../../types';
import { IconsEnum } from '../../assets/icons/types';

import { Icon } from '../Icon';
import { Tooltip } from '../tooltips/Tooltip';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';
import Translate from '../Translate/Translate';

interface TooltipAlertMessageProps {
  className?: string;
  i18nMessage?: I18nText | null;
  message?: string | ReactNode | null;
  tooltipPlacement?: TooltipPlacement;
  withArrow?: boolean;
}

function TooltipAlertMessage({
  className,
  i18nMessage,
  message,
  tooltipPlacement,
  withArrow
}: TooltipAlertMessageProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!i18nMessage && !message) {
    return null;
  }

  return (
    <>
      <span className={className} ref={setReferenceTooltipElement}>
        <Icon
          className="h-5 w-5 text-red-400 dark:text-red-300"
          icon={IconsEnum.X_CIRCLE}
        />
      </span>
      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={tooltipPlacement}
        withArrow={withArrow}
      >
        <h3 className="text-sm font-medium">
          {i18nMessage ? <Translate id={i18nMessage} /> : message}
        </h3>
      </Tooltip>
    </>
  );
}

export default TooltipAlertMessage;
