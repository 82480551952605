import { gql } from 'graphql-request';

export const UPDATE_TEAM_PAYMENT_METHODS_QUERY = gql`
  mutation UpdateTeamPaymentMethods($uuid: ID!, $paymentMethodIds: [ID!]!) {
    updateTeamPaymentMethods(
      input: { uuid: $uuid, paymentMethodIds: $paymentMethodIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
