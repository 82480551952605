import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  TextFilterType
} from '../../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

import {
  PermissionAndroid,
  PermissionDanger,
  PermissionFrontend,
  PermissionIos,
  PermissionBffAt,
  PermissionRoleIds,
  PermissionType
} from './permissionsFields';

export interface FetchPermissionsFilters {
  name?: TextFilterType;
  action?: TextFilterType;
  android?: PermissionAndroid;
  danger?: PermissionDanger;
  frontend?: PermissionFrontend;
  ios?: PermissionIos;
  bffAt?: PermissionBffAt;
  roleIds?: PermissionRoleIds;
  type?: PermissionType;
  createdAt?: DateFilterType;
}

export const enum FetchPermissionsSortableFields {
  ID = 'id',
  ACTION = 'action',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}

export const enum FetchPermissionsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  ACTION_ASC = 'ACTION_ASC',
  ACTION_DESC = 'ACTION_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type FetchPermissionsGqlQuery = FetchItemsGqlQuery;

export type FetchPermissionsCacheKey = FetchItemsCacheKey;

export type FetchPermissionsFetched = FetchItemsFetched;
export type FetchPermissionsErrorMessage = FetchItemsErrorMessage;
export type FetchPermissionsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchPermissionsPage = FetchItemsPage;
export type FetchPermissionsLimit = FetchItemsLimit;
export type FetchPermissionsSort = FetchItemsSort; // FetchPermissionsSortTypes[];
export type FetchPermissionsTotalCount = FetchItemsTotalCount;
export type FetchPermissionsFilterPermissions =
  FetchItemsFilterItems<FetchPermissionsFilters>;
export type FetchPermissionsClearPermissionsFilters =
  FetchItemsClearItemsFilters;
export type FetchPermissionsSortPermissions = FetchItemsSortItems;
export type FetchPermissionsPaginatePermissions = FetchItemsPaginateItems;
export type FetchPermissionsPrefetchPermissions = FetchItemsPrefetchItems;

export type FetchPermissionsShowPermission = FetchItemsShowItem;
export type FetchPermissionsEditPermission = FetchItemsEditItem;

export type FetchPermissionsChecked = Checked;
export type FetchPermissionsCheckedHash = CheckedHashItem;
export type FetchPermissionsOnSetCheckedIds = OnSetCheckedIds;
export type FetchPermissionsCheckedAll = CheckedAll;
export type FetchPermissionsOnCheckAll = OnCheckAll;
