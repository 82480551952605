import React, { Fragment } from 'react';
import cl from 'classnames';

import {
  UserBlocked,
  UserClient,
  UserCurrentTeamName,
  UserCurrentTeamNanoID,
  UserFinanceRoleName,
  UserFullName,
  UserUUID
} from '../../../../../usersTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { Icon } from '../../../../../../../helpers/Icon';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { TeamPath } from '../../../../../../teams/TeamPath';

export type SingleUserProfileSecondaryMenuWrapperHeadUserInfoUser = {
  uuid: UserUUID;
  fullName: UserFullName;
  financeRoleName: UserFinanceRoleName;
  blocked: UserBlocked;
  client: UserClient;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
    name: UserCurrentTeamName;
  };
};

interface SingleUserProfileSecondaryMenuWrapperHeadUserInfoProps {
  user: SingleUserProfileSecondaryMenuWrapperHeadUserInfoUser;
}

function SingleUserProfileSecondaryMenuWrapperHeadUserInfo({
  user
}: SingleUserProfileSecondaryMenuWrapperHeadUserInfoProps) {
  const currentUser = useCurrentUser();

  const isSelf = currentUser.uuid === user.uuid;

  return user.client ? (
    <Fragment>
      <div className="flex">
        <h2 className="font-medium text-md line-clamp-1">{user.fullName}</h2>
        <div className="flex">
          <Icon
            icon={user.blocked ? IconsEnum.BLOCK : IconsEnum.BADGE_CHECK}
            className={cl(
              'self-center h-4 w-4 ml-1',
              user.blocked ? 'text-red-500' : 'text-green-600'
            )}
          />
        </div>
      </div>

      {user.currentTeam?.nanoId && (
        <div className="flex items-center text-xs gap-4 leading-none max-w-full truncate">
          <NextPureLinkHelper
            href={
              isSelf
                ? TeamPath.currentCompanyProfile()
                : TeamPath.companyProfile(user.currentTeam.nanoId)
            }
            text={user.currentTeam.name}
            className="text-gray-400 dark:text-gray-500 truncate hover:underline"
          />
        </div>
      )}
    </Fragment>
  ) : (
    <Fragment>
      <div className="flex">
        <h2 className="font-medium text-md line-clamp-1">{user.fullName}</h2>
        <div className="flex">
          <Icon
            icon={user.blocked ? IconsEnum.BLOCK : IconsEnum.BADGE_CHECK}
            className={cl(
              'self-center h-4 w-4 ml-1',
              user.blocked ? 'text-red-500' : 'text-green-600'
            )}
          />
        </div>
      </div>

      <div className="flex items-center text-xs gap-4 leading-none max-w-full truncate">
        <div className="text-gray-400 dark:text-gray-500 truncate">
          {user.financeRoleName}
        </div>
      </div>
    </Fragment>
  );
}

export default SingleUserProfileSecondaryMenuWrapperHeadUserInfo;
