import { FetchReportSectionsSortTypes } from './reportSectionsTypes';

export enum ReportSectionsPermissions {
  READ_ADMIN_MENU_REPORT_SECTIONS_LINK = 'read_admin_menu_report_sections_link',

  READ_REPORT_SECTIONS_INDEX_PAGE = 'read_report_sections_index_page',

  READ_DELETE_REPORT_SECTION_BUTTON = 'read_delete_report_section_button',
  READ_UPDATE_REPORT_SECTION_BUTTON = 'read_update_report_section_button',
  READ_CREATE_REPORT_SECTION_BUTTON = 'read_create_report_section_button'
}

export const INITIAL_REPORT_SECTIONS_FILTERS = {};
export const INITIAL_REPORT_SECTIONS_PAGE = 1;
export const INITIAL_REPORT_SECTIONS_SORT = [
  FetchReportSectionsSortTypes.CREATED_AT_DESC
];
export const INITIAL_REPORT_SECTIONS_LIMIT = 50;
