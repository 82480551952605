import React, {
  useState,
  useCallback,
  CSSProperties,
  ForwardedRef,
  MutableRefObject,
  ChangeEvent
} from 'react';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

import { FieldIcons } from '../../../../../../../types';

function autosizeInput(input: HTMLInputElement): string {
  const pl = parseInt(getComputedStyle(input).paddingLeft, 10);
  const pr = parseInt(getComputedStyle(input).paddingRight, 10);
  return `calc(${pl + pr + 2}px + ${input.value.length || 1}ch)`;
}

function getInputStyles(input: HTMLInputElement | null): CSSProperties {
  if (!input) {
    return {
      width: 'calc(74px + 1ch)'
    };
  }
  return {
    width: autosizeInput(input)
  };
}

interface CompanyWithdrawFormAmountInputProps {
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
}

const CompanyWithdrawFormAmountInput = React.forwardRef<
  HTMLInputElement,
  CompanyWithdrawFormAmountInputProps
>(
  (
    { name, onChange, disabled, min, max }: CompanyWithdrawFormAmountInputProps,
    ref?: ForwardedRef<HTMLInputElement>
  ) => {
    const [inputElement, setInputElement] = useState<HTMLInputElement>(null);

    const handleNodeChange = useCallback(
      (node: HTMLInputElement | null) => {
        setInputElement(node);

        isFunction(ref) && ref(node);
        isObject(ref) &&
          ((ref as MutableRefObject<HTMLInputElement>).current = node);
      },
      [ref]
    );

    return (
      <label className="flex justify-center items-center p-2 mb-1 focus-within:ring-4 ring-blue-300 cursor-text focus-within:hover:bg-transparent dark:focus-within:hover:bg-transparent rounded-md">
        <span className="text-3xl font-mono font-semibold leading-normal">
          {FieldIcons.DOLLAR}
        </span>
        <input
          name={name}
          ref={handleNodeChange}
          type="number"
          className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-transparent border-0 -ml-8 -mr-9 px-9 leading-normal text-3xl font-mono font-semibold p-0 w-auto focus:ring-0"
          min={min}
          max={max}
          step={1}
          placeholder="0"
          style={getInputStyles(inputElement)}
          disabled={disabled}
          onChange={onChange}
        />
      </label>
    );
  }
);

CompanyWithdrawFormAmountInput.displayName = 'CompanyWithdrawFormAmountInput';

export default CompanyWithdrawFormAmountInput;
