import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  TextFilterType
} from '../../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

export interface FetchReportsFilters {
  userId?: IdFilterType;
  reportType?: TextFilterType;
  createdAt?: DateFilterType;
}

export const enum FetchReportsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export const enum FetchReportsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchReportsGqlQuery = FetchItemsGqlQuery;

export type FetchReportsCacheKey = FetchItemsCacheKey;

export type FetchReportsFetched = FetchItemsFetched;
export type FetchReportsErrorMessage = FetchItemsErrorMessage;
export type FetchReportsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchReportsPage = FetchItemsPage;
export type FetchReportsLimit = FetchItemsLimit;
export type FetchReportsSort = FetchItemsSort; // FetchReportsSortTypes[];
export type FetchReportsTotalCount = FetchItemsTotalCount;
export type FetchReportsFilterReports =
  FetchItemsFilterItems<FetchReportsFilters>;
export type FetchReportsClearReportsFilters = FetchItemsClearItemsFilters;
export type FetchReportsSortReports = FetchItemsSortItems;
export type FetchReportsPaginateReports = FetchItemsPaginateItems;
export type FetchReportsPrefetchReports = FetchItemsPrefetchItems;

export type FetchReportsShowReport = FetchItemsShowItem;
export type FetchReportsEditReport = FetchItemsEditItem;

export type FetchReportsChecked = Checked;
export type FetchReportsCheckedHash = CheckedHashItem;
export type FetchReportsOnSetCheckedIds = OnSetCheckedIds;
export type FetchReportsCheckedAll = CheckedAll;
export type FetchReportsOnCheckAll = OnCheckAll;
