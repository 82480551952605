import { gql } from 'graphql-request';

export const UPDATE_TEAM_SALES_MANAGERS_QUERY = gql`
  mutation UpdateTeamSalesManagers($uuid: ID!, $salesManagerIds: [ID!]!) {
    updateTeamSalesManagers(
      input: { uuid: $uuid, salesManagerIds: $salesManagerIds }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
