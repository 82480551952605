import { gql } from 'graphql-request';
import { ID } from '../../../types';

export interface UpdateUserImageQueryResponse {
  id: ID;
}

export const UPDATE_USER_IMAGE_QUERY = gql`
  mutation UpdateUserImage($uuid: ID!, $imageId: ID!) {
    updateUserImage(input: { uuid: $uuid, imageId: $imageId }) {
      recordUuid
      status
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
