import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MaterialsFiltersNavBasePath } from '../../../../../../materials/materialsTypes';

import { ThreeDStockIndexPageMaterialsCategoriesNavCategory } from '../../ThreeDStockIndexPageMaterialsCategoriesNav.types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';

import { MaterialPath } from '../../../../../../materials/MaterialPath';
import { getChangeMaterialsFiltersCategoriesData } from '../../../../../../materials/utils/getChangeMaterialsFiltersCategoriesData';

interface ThreeDStockIndexPageMaterialsCategoriesNavItemProps {
  categories: ThreeDStockIndexPageMaterialsCategoriesNavCategory[];
  category: ThreeDStockIndexPageMaterialsCategoriesNavCategory;
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  withChevron?: boolean;
}

function ThreeDStockIndexPageMaterialsCategoriesNavItem({
  categories,
  category,
  filtersNavBasePath,
  withChevron = false
}: ThreeDStockIndexPageMaterialsCategoriesNavItemProps) {
  return (
    <div className="flex items-center group relative -mx-4 px-4 py-1.5 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
      <NextLinkHelper
        className="flex-1 text-sm focus-visible:ring-none"
        href={MaterialPath.indexFiltersCustomBaseQuery(
          filtersNavBasePath,
          getChangeMaterialsFiltersCategoriesData({
            materialCategory: category,
            categories
          }).changeFilters
        )}
      >
        {category.localizedName}
        <span className="absolute inset-0" aria-hidden="true" />
      </NextLinkHelper>

      {withChevron && (
        <Icon
          icon={IconsEnum.CHEVRON_RIGHT}
          className="h-5 w-5 flex-shrink-0"
        />
      )}
    </div>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNavItem;
