import { IconsEnum } from '../../../../../assets/icons/types';
import { CompanyNanoID } from '../../../../companies/companiesTypes';
import { I18nText, ClassName } from '../../../../../types';
import { CreateGeneralLedgerCacheKeys } from '../../../generalLedgersTypes';

import {
  CreateGeneralLedgerForm,
  useCreateGeneralLedgerForm
} from '../../forms/CreateGeneralLedgerForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { generalLedgersKeys, words } from '../../../../../locales/keys';

const CREATE_GENERAL_LEDGER_FORM = 'create-general-ledger-form';

interface CreateGeneralLedgerModalButtonProps {
  companyNanoId?: CompanyNanoID;
  cacheKeys?: CreateGeneralLedgerCacheKeys;
  i18nText?: I18nText;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function CreateGeneralLedgerModalButton({
  companyNanoId,
  cacheKeys,
  i18nText,
  className,
  icon,
  iconClassName
}: CreateGeneralLedgerModalButtonProps) {
  const {
    registerFields,
    handleCreateGeneralLedger,
    resetCreateGeneralLedgerForm,
    createGeneralLedgerReset,
    createGeneralLedgerLoading,
    createGeneralLedgerErrorMessage,
    validationErrors
  } = useCreateGeneralLedgerForm({ companyNanoId, cacheKeys });

  return (
    <FormModalButton
      className={className}
      form={CREATE_GENERAL_LEDGER_FORM}
      i18nTitle={generalLedgersKeys.createSubbook}
      i18nText={i18nText}
      i18nSubmitText={words.create}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={resetCreateGeneralLedgerForm}
      isLoading={createGeneralLedgerLoading}
      onClose={createGeneralLedgerReset}
      onSubmit={handleCreateGeneralLedger}
    >
      <CreateGeneralLedgerForm
        form={CREATE_GENERAL_LEDGER_FORM}
        isLoading={createGeneralLedgerLoading}
        registerName={registerFields.registerName}
        nameValidationError={validationErrors.nameValidationError}
        defaultShareValidationError={
          validationErrors.defaultShareValidationError
        }
        registerInvoiced={registerFields.registerInvoiced}
        registerDefaultShare={registerFields.registerDefaultShare}
      />

      <div className="px-4">
        <AlertMessage message={createGeneralLedgerErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateGeneralLedgerModalButton;
