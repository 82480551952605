import React from 'react';

import { I18nText } from '../../../../../types';

import {
  FetchMaterialsFilters,
  MaterialClientID,
  MaterialsFiltersNavBasePath
} from '../../../../materials/materialsTypes';

import { useThreeDStockMaterialsCategories } from '../../../hooks/useThreeDStockMaterialsCategories';

import { ThreeDStockIndexPageMaterialsCategoriesNavBody } from './components/ThreeDStockIndexPageMaterialsCategoriesNavBody';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface ThreeDStockIndexPageMaterialsCategoriesNavProps {
  allCustomI18nText?: I18nText;
  clientIds?: MaterialClientID[];
  filtersNavBasePath: MaterialsFiltersNavBasePath;
  isShowAll?: boolean;
  materialsFilters?: FetchMaterialsFilters;
  withNavHead?: boolean;
}

function ThreeDStockIndexPageMaterialsCategoriesNav({
  allCustomI18nText,
  clientIds,
  filtersNavBasePath,
  isShowAll,
  materialsFilters,
  withNavHead
}: ThreeDStockIndexPageMaterialsCategoriesNavProps) {
  const {
    materialCategoriesErrorMessage,
    materialCategoriesFetched,
    materialCategoriesIsPlaceholderData,
    materialCategoriesWithParents,
    parentCategories
  } = useThreeDStockMaterialsCategories({ clientIds, materialsFilters });

  return (
    <div className="p-4">
      <AlertMessage message={materialCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          materialCategoriesIsPlaceholderData || materialCategoriesFetched
        }
      >
        <ThreeDStockIndexPageMaterialsCategoriesNavBody
          allCustomI18nText={allCustomI18nText}
          filtersNavBasePath={filtersNavBasePath}
          isShowAll={isShowAll}
          materialCategories={materialCategoriesWithParents}
          parentCategories={parentCategories}
          withNavHead={withNavHead}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageMaterialsCategoriesNav;
