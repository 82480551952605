import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  TextFilterType
} from '../../../types';

import { PageUUID } from './pagesFieldsTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

export interface FetchPagesFilters {
  name?: TextFilterType;
  createdAt?: DateFilterType;
}

export enum FetchPagesSortableFields {
  ID = 'id',
  NAME = 'name',
  NAME_EN = 'nameEn',
  CREATED_AT = 'createdAt'
}

export enum FetchPagesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchPagesGqlQuery = FetchItemsGqlQuery;

export type FetchPagesCacheKey = FetchItemsCacheKey;

export type FetchPagesFetched = FetchItemsFetched;
export type FetchPagesErrorMessage = FetchItemsErrorMessage;
export type FetchPagesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchPagesPage = FetchItemsPage;
export type FetchPagesLimit = FetchItemsLimit;
export type FetchPagesSort = FetchItemsSort; // FetchPagesSortTypes[];
export type FetchPagesTotalCount = FetchItemsTotalCount;
export type FetchPagesFilterPages = FetchItemsFilterItems<FetchPagesFilters>;
export type FetchPagesClearPagesFilters = FetchItemsClearItemsFilters;
export type FetchPagesSortPages = FetchItemsSortItems;
export type FetchPagesPaginatePages = FetchItemsPaginateItems;
export type FetchPagesPrefetchPages = FetchItemsPrefetchItems;

export type FetchPagesShowPage = FetchItemsShowItem<PageUUID>;
export type FetchPagesEditPage = FetchItemsEditItem<PageUUID>;

export type FetchPagesChecked = Checked;
export type FetchPagesCheckedHash = CheckedHashItem;
export type FetchPagesOnSetCheckedIds = OnSetCheckedIds;
export type FetchPagesCheckedAll = CheckedAll;
export type FetchPagesOnCheckAll = OnCheckAll;
