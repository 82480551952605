import { FetchRolesSortTypes, RoleID } from './rolesTypes';

export const INITIAL_ROLES_FILTERS = {};
export const INITIAL_ROLES_PAGE = 1;
export const INITIAL_ROLES_SORT = [FetchRolesSortTypes.CREATED_AT_DESC];
export const INITIAL_ROLES_LIMIT = 50;

export enum RolesPermissions {
  READ_ADMIN_MENU_ROLES_LINK = 'read_admin_menu_roles_link',
  READ_ROLES_INDEX_PAGE = 'read_roles_index_page',
  READ_ROLES_TEST_PAGE = 'read_roles_test_page',
  READ_ROLES_SEARCH_FILTERS_POPOVER = 'read_roles_search_filters_popover'
}

export const financeRoleIds = ['2', '3', '5', '6', '9', '15', '18'];

export const artDirectorFinanceRoleId: RoleID = '6';
export const projectManagerFinanceRoleId: RoleID = '18';
