import React from 'react';

import { TeamFields, TeamUUID } from '../../../../../../teamsTypes';

import { useUpdateTeamField } from '../../../../../../hooks/useUpdateTeamField';

import {
  TeamAvatar,
  TeamAvatarTeam
} from '../../../../../../helpers/TeamAvatar';
import { DropzoneImageModalButton } from '../../../../../../../../helpers/buttons/DropzoneImageModalButton';
import { CheckPermissions } from '../../../../../../../../helpers/CheckPermissions';

import { IconsEnum } from '../../../../../../../../assets/icons/types';
import { teamsKeys } from '../../../../../../../../locales/keys';
import { TeamsPermissions } from '../../../../../../teamsConstants';

export type TeamProfileSecondaryMenuWrapperHeadTeamAvatarTeam = {
  uuid: TeamUUID;
} & TeamAvatarTeam;

interface TeamProfileSecondaryMenuWrapperHeadTeamAvatarProps {
  team: TeamProfileSecondaryMenuWrapperHeadTeamAvatarTeam;
}

function TeamProfileSecondaryMenuWrapperHeadTeamAvatar({
  team
}: TeamProfileSecondaryMenuWrapperHeadTeamAvatarProps) {
  const { handleUpdateTeamField, updateTeamErrorMessage } =
    useUpdateTeamField<string>({
      teamUuid: team.uuid,
      fieldName: TeamFields.IMAGE_ID
    });

  return (
    <div className="w-10 h-10 sm:w-12 sm:h-12 rounded bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900 flex items-center justify-center overflow-hidden relative self-center">
      <TeamAvatar className="w-full h-full block object-cover" team={team} />

      <CheckPermissions action={TeamsPermissions.CHANGE_COMPANY_IMAGE_FIELD}>
        <DropzoneImageModalButton
          type="images"
          className="absolute inset-0 transition-all hover:bg-black opacity-0 hover:opacity-100 hover:bg-opacity-50 flex items-center justify-center cursor-pointer text-white"
          buttonText=""
          i18nTitle={teamsKeys.editCompanyPicture}
          onSubmit={handleUpdateTeamField}
          submitErrorMessage={updateTeamErrorMessage}
          resetOnClose
          maxFiles={1}
          icon={IconsEnum.UPLOAD_SOLID}
        />
      </CheckPermissions>
    </div>
  );
}

export default TeamProfileSecondaryMenuWrapperHeadTeamAvatar;
